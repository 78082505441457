import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router';

function CoachMyProfile() {

  const[userData,setUserData] = useState('');
  const [username, setUsername] = useState("");
  const [category, setCategory] = useState("");
  const [height, setHeight] = useState("");
  const [income, setIncome] = useState("");
  const [postcode, setPostcode] = useState("");
  const [pricePerHour, setPricePerHour] = useState("");
  const [relationshipStatus, setRelationshipStatus] = useState("");
  const [serviceOffered, setServiceOffered] = useState("");
  const [skills, setSkills] = useState("");
  const [weight, setWeight] = useState("");
  const [belief, setBelief] = useState("");

  const id = localStorage.getItem('userId');
  const history = useHistory();


  const getUser = async () => {
    
    
    console.log(id)
    const user = await axios.get(`http://localhost:3001/api/getusers/${id}`);
    console.log(user)
    setUsername(user.data[0].userName);
    setCategory(user.data[0].category);
    setHeight(user.data[0].height);
    setIncome(user.data[0].income);
    setPostcode(user.data[0].postcode);
    setPricePerHour(user.data[0].priceperhour);
    setRelationshipStatus(user.data[0].relationShipStatus);
    setServiceOffered(user.data[0].serviceOffered);
    setSkills(user.data[0].skills);
    setWeight(user.data[0].weight);
    setBelief(user.data[0].belief);
  }

  useEffect(() => {

  getUser();

  },[])

  const ProfileUpdate = async (e) => {
    // e.preventDefault();
    // S3FileUpload.uploadFile(this.state.formFields.blogImage, config)
    //     .then((data) => {
    //       const blogImageUrl = data.key.slice(
    //         data.key.lastIndexOf("/") + 1,
    //         data.key.length
    //       );
    const data = {
      userName: username,
      category:category,
      height: height,
      income:income,
      priceperhour: pricePerHour,
      postcode:postcode,
      relationShipStatus: relationshipStatus,
      serviceOffered:serviceOffered,
      skills: skills,
      weight:weight,
      belief:belief,
    }
    axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/api/users/" + id, data)
      .then((res) => console.log(res), history.push("/myprofile-edit"))
      .catch((err) => console.log(err));
  };


  return (
    <div className="EditProfile">
    <h3>Edit Profile</h3>
    <form onSubmit={ProfileUpdate}>
  <div class="form-row">
    <div class="col">
    <label>User Name</label>
      <input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" class="form-control" placeholder="User Name"/>
    </div>
    <div class="col">
    <label>Relationship status </label>
      <input  value={relationshipStatus} onChange={(e)=>setRelationshipStatus(e.target.value)} type="text" class="form-control" placeholder="Relationship status"/>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
    <label>Postcode</label>
      <input value={postcode} onChange={(e)=>setPostcode(e.target.value)} type="text" class="form-control" placeholder="Postcode"/>
    </div>
    <div class="col">
    <label>Skills</label>
      <input  value={skills} onChange={(e)=>setSkills(e.target.value)} type="text" class="form-control" placeholder="Skills"/>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
    <label>Weight</label>
      <input value={weight} onChange={(e)=>setWeight(e.target.value)} type="text" class="form-control" placeholder="Weight"/>
    </div>
    <div class="col">
    <label>Height</label>
      <input  value={height} onChange={(e)=>setHeight(e.target.value)} type="text" class="form-control" placeholder="Height"/>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
    <label>Category</label>
      <input value={category} onChange={(e)=>setCategory(e.target.value)} type="text" class="form-control" placeholder="Category"/>
    </div>
    <div class="col">
    <label>Price/hour</label>
      <input  value={pricePerHour} onChange={(e)=>setPricePerHour(e.target.value)} type="text" class="form-control" placeholder="Price/hour"/>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
    <label>Belief</label>
      <input value={belief} onChange={(e)=>setBelief(e.target.value)} type="text" class="form-control" placeholder="Belief"/>
    </div>
    <div class="col">
    <label>Income</label>
      <input  value={income} onChange={(e)=>setIncome(e.target.value)} type="text" class="form-control" placeholder="Income"/>
    </div>
  </div>
  <div class="form-row">
    <div class="col-6">
    <label>Services Offered</label>
      <input value={serviceOffered} onChange={(e)=>setServiceOffered(e.target.value)} type="text" class="form-control" placeholder="Services Offered"/>
    </div>
    
  </div>
  <button type="submit" className="product-button">
          Update
        </button>
  
</form>
  </div>
  )
}

export default CoachMyProfile