import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";

const CreateSuppliers = () => {
  const [supplierName, setSupplierName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dispatchAddress, setDispatchAddress] = useState("");
  const [password, setPassword] = useState("");
  const [abn, setAbn] = useState("");
  const [status, setStatus] = useState("");
  const history = useHistory();

  const url = "http://3.144.43.94:3001/api/users";

  toast.configure();
  useEffect(() => {
    // supplierSubmit();
  }, [url]);

  const supplierNameChange = (e) => {
    setSupplierName(e.target.value);
  };
  const companyNameChange = (e) => {
    setCompanyName(e.target.value);
  };
  const mobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };
  const emailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
    console.log(e.target.value.toLowerCase());
  };
  const dispatchAddressChange = (e) => {
    setDispatchAddress(e.target.value);
  };
  const passwordChange = (e) => {
    setPassword(e.target.value);
  };
  const abnChange = (e) => {
    setAbn(e.target.value);
  };
  const statusChange = (e) => {
    setStatus(e.target.value);
  };

  const supplierSubmit = async (e) => {
    e.preventDefault();
    console.log(status);
    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_ENDPOINT + "/api/users",
        data: {
          userType: "seller",
          userName: supplierName,
          companyName: companyName,
          mobileNumber: mobileNumber,
          email: email,
          dispatchAddress: dispatchAddress,
          password: password,
          abn: abn,
          status: status,
        },
      })
        .then((res) => {
          setSupplierName("");
          setCompanyName("");
          setMobileNumber("");
          setEmail("");
          setDispatchAddress("");
          setPassword("");
          setAbn("");
          setStatus("");
          history.push("/list-suppliers");
          console.log(res);
        })
        .catch((err) => {
          toast.error('Email has already taken')
          console.log(err);
        });
      console.log("connected");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="CreateSuppliers">
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
      <h2>Add Suppliers</h2>
      <form onSubmit={supplierSubmit}>
        {/* <form > */}
        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Supplier Name</label>
            </div>
            <input
              type="text"
              value={supplierName}
              onChange={supplierNameChange}
            />
          </div>
          <div className="form-width">
            <div>
              <label>Company Name</label>
            </div>
            <input
              type="text"
              value={companyName}
              onChange={companyNameChange}
            />
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Mobile</label>
            </div>
            <input
              type="number"
              value={mobileNumber}
              onChange={mobileNumberChange}
            />
          </div>
          <div className="form-width">
            <div>
              <label>Email</label>
            </div>
            <input type="email" value={email} onChange={emailChange} />
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Dispatch Address</label>
            </div>
            <input
              type="text"
              value={dispatchAddress}
              onChange={dispatchAddressChange}
            />
          </div>
          <div className="form-width">
            <div>
              <label>Password</label>
            </div>
            <input type="text" value={password} onChange={passwordChange} />
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>ABN</label>
            </div>
            <input type="text" value={abn} onChange={abnChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Status</label>
            </div>
            <select name="status" value={status} onChange={statusChange}>
              <option></option>
              <option value="active">Active</option>
              <option value="inactive">InActive</option>
            </select>
          </div>
        </div>

        <button type="submit" value="" className="supplier-button">
          ADD
        </button>
      </form>
    </div>
  );
};

export default CreateSuppliers;
