import { useState } from "react/cjs/react.development";
import axios from "axios";

import "./index.css";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setReTypePassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const newPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const retypePasswordChange = (e) => {
    setReTypePassword(e.target.value);
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    const changePassword = { password: newPassword };
    if (newPassword === retypePassword) {
      try {
        const password = await axios
          .put(
            "http://3.144.43.94:3001/api/adminUsers/6152c7cdf19381256b6bdf9a",
            changePassword
          )
          .then(
            setError(false),
            setSuccess(true),
            setNewPassword(""),
            setReTypePassword("")
          );
      } catch (err) {
        console.log(err);
      }
    } else {
      setSuccess(false);
      setError(true);
      setNewPassword(""); 
      setReTypePassword("");
    }
  };
  return (
    <div className="ChangePassword">
      <h3>Change Password</h3>
      <form>
        {success && <h5 className="green">Password Changed Successfully</h5>}
        {error && <h5 className="red">Password Mismatch</h5>}
        <input
          type="password"
          value={newPassword}
          onChange={newPasswordChange}
          placeholder="New Password"
        />
        <input
          type="password"
          value={retypePassword}
          onChange={retypePasswordChange}
          placeholder="Re-Type Password"
        />
        <button type="submit" onClick={submitPassword}>
          Save
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
