import axios from 'axios';
import React from 'react';
import './index.css';

export default class FeaturedQuote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quote:'',
            quoteList: [],
            featuredQuote: [],
            featuredQuoteList: [],

        };
    }

    componentDidMount() {
        this.getFeaturedQuotes();
        this.getQuoteList();
    }
quoteId='61acf87610394f51855d80a0'
    getQuoteList = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/quote')
        .then(response => {
            this.setState({quoteList: response.data});
        }).catch(err => console.log(err));
    }

    handleSelect = (quote) => {
        this.setState({quote: quote});

    }
    getFeaturedQuotes = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/featured-quote')
        .then(response => {
            let featuredQuote = response.data.filter(item => item.category === 'quote');
            featuredQuote = featuredQuote.sort((a, b) => {
                if (parseInt(a['item']) > parseInt(b['item'])) {
                    return 1
                };
                return -1;
            });
            
            

            this.setState({
                featuredQuoteList: response.data,
                featuredQuote,
            });
        }).catch(err => console.log(err));
    }

    handleUpdate = (featuredQuote) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/quoteoftheday/' + '61acf87610394f51855d80a0', {quote:this.state.quote})
        .then(response => {
            console.log('response: ', response);
        }).catch(err => console.log(err));
    }



    render() {
        return (
            <div className="fi-container">
                <div className="row">
                    <strong>Featured Quote</strong>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <select
                            onChange={(e)=>this.handleSelect(e.target.value)}
                            className="fi-select"
                            >
                            {
                                this.state.quoteList.map((quoteData) => {
                                    return (
                                        <option value={quoteData.quote} id={quoteData._id} key={quoteData._id}>{quoteData.quote}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredQuote[0])}
                            className="fi-button">UPDATE</button>
                    </div>
                </div>
            </div>
        );
    }
}