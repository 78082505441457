import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./index.css";

const QuoteEdit = () => {
  let { id } = useParams("");

  const [quote, setQuote] = useState("");
  const history = useHistory();

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/quote";

  useEffect(() => {
    getQuoteById();
  }, [url]);

  const getQuoteById = async () => {
    try {
      const quote = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/quote/" + id);
      setQuote(quote.data.quote);
    } catch (err) {
      console.log(err);
      console.log(id);
    }
  };

  const quoteChange = (e) => {
    setQuote(e.target.value);
    console.log(e.target.value);
  };

  const quoteUpdate = async (e) => {
    e.preventDefault();
    const data = {quote: quote}
    axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/api/quote/" + id, data)
      .then((res) => console.log(res))
      .then(() => history.push("/quotes"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="QuoteEdit CreateQuotes">
      <h5 style={{marginBottom: 15}}>Edit Quote</h5>
      <form onSubmit={quoteUpdate}>
        <textarea
          type="text"
          placeholder="Enter Quote Of The Day"
          value = {quote}
          onChange={quoteChange}
        />
        <button type="submit">
          Update
        </button>
      </form>
    </div>
  );
};

export default QuoteEdit;
