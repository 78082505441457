import * as actionTypes from "./orderTypes";

const INTIAL_STATE = {
  orderDetails: [],
};

const orderReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
