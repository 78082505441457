import axios from "axios";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./index.css";

const OrderDetails = (historyProp) => {
  const [supplierData, setSupplierData] = useState([]);
  const id = useSelector((state) => state.login.user.id);
  let no = 0;
  let orderedOrderId = historyProp.location.state.orderId;
  let orderedCustomerName = historyProp.location.state.customerName;
  let orderedTotal = historyProp.location.state.total;
  let orderedPurchasedOn = historyProp.location.state.purchasedOn;
  let orderedDeliveryOption = historyProp.location.state.deliveryOption;
  let orderedAddress = historyProp.location.state.address;

  useEffect(async () => {
    getOrders();
    console.log(historyProp);
  }, []);

  const getOrders = async () => {
    const orderApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/transaction");
    const orderData = orderApi.data;
    let individualSupplier = [];
    let supp = [];
    orderData.forEach((data) => {
      individualSupplier.push(data.productDetails);
    });
    individualSupplier.map((data) => {
      data.forEach((d) => {
        supp.push(d);
      });
    });
    console.log(supp);
    setSupplierData(supp);
  };

  return (
    <div className="od-container">
      <div className="row">
        <div className="col-md-6 blog-heading-nav" style={{paddingLeft: '0px'}}>
          Order Details
        </div>
      </div>
      <div className="OrderDdetails">
        <form>
          <div className="form-width-2">
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Order ID</label>
              </div>
              <input type="text" 
              value = {orderedOrderId} 
              />
            </div>
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Customer Name</label>
              </div>
              <input type="text" 
              value = {orderedCustomerName} 
              />
            </div>
          </div>

          <div className="form-width-2">
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Customer Mobile Number</label>
              </div>
              <input type="number" />
            </div>
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Bill Value</label>
              </div>
              <input type="number" 
              value = {orderedTotal}
              />
            </div>
          </div>

          <div className="form-width-2">
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Purchased On</label>
              </div>
              <input type="text" 
              value = {orderedPurchasedOn} 
              />
              <div style={{marginTop: 10}}>
                <label>Delivery Option</label>
              </div>
              <input type="text" 
              value = {orderedDeliveryOption} 
              />
            </div>
            <div className="form-width">
              <div style={{marginTop: 10}}>
                <label>Address</label>
              </div>
              <textarea type="text" value = {orderedAddress} />
            </div>
          </div>
        </form>
      </div>
      <Table className="od-pd-table" hover>
        <thead>
          <tr>
            <th>No</th>
            <th style={{width: '40vw'}}>Product ID</th>
            <th style={{width: '20vw'}}>Product Name</th>
            <th style={{width: '20vw'}}>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {supplierData.slice(0, 3)
            .filter((data) => data.sId === id)
            .map((data) => (
              <tr className="table-even">
                <td className="table-body" data-label="No">
                  {(no += 1)}
                </td>
                <td className="table-body" data-label="Product ID">
                  {data.pId}
                </td>
                <td className="table-body" data-label="Product Name">
                  {data.productName}
                </td>
                <td className="table-body" data-label="Quantity">
                  {data.pQty}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OrderDetails;
