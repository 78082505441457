import axios from "axios";
import { useRef,useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import S3 from 'react-aws-s3'

import "./index.css";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CreateProfile = () => {
  
  const [title, settitle] = useState("");
  const [value, setValue] = useState("");
  const [specification, setSpecification] = useState("");
  const [details, setDeatils] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [uploading, setupLoading] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");
  const[file,setFile] = useState(null);
  const fileInput=useRef();
  let { id } = useParams("");
  let {profileNum} = useParams("");

  const userId = localStorage.getItem('coachId');

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/profile";

 
  const titleChange = (e) => {
    settitle(e.target.value);
  };
  const valueChange = (e) => {
    setValue(e.target.value);
  };
  const specificationChange = (e) => {
    setSpecification(e.target.value);
  };
  const deatailsChange = (e) => {
    setDeatils(e.target.value);
  };

  const profileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_ENDPOINT + "/api/profile",
        data: {
          title: title,
          value: value,
          specification: specification,
          details: details,
          id: userId,
          profileImage:profileImageURL
        },
      }).then(
        settitle(""),
        setValue(""),
        setSpecification(""),
        setDeatils(""),
        setLoading(false),
        history.push("/coach-profile")
      );
      console.log("connected");
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClick = (e) => {
    setupLoading(true)
    e.preventDefault();
    let file = fileInput.current.files[0];
    
    let newFileName =  userId + "-" + profileNum ;
    setProfileImageURL(newFileName)
 
    const config = {
     bucketName: process.env.REACT_APP_BUCKET_NAME,
     dirName: process.env.REACT_APP_DIR_NAME,
     region: process.env.REACT_APP_REGION,
     accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
     secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
   };
 
   const ReactS3Client = new S3(config);
 
   ReactS3Client.uploadFile(file,newFileName).then(data=> {
     console.log(data);
     if(data.status === 204) {
       console.log('success')
       setupLoading(false)
     } else {
       console.log('fail')
     }
   })
  }
  return (
    <div className="ProfileDetails">
      <h3>Profile Details</h3>
      <form onSubmit={profileSubmit}>
        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Profile Title</label>
            </div>
            <input type="text" value={title} onChange={titleChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Value</label>
            </div>
            <input type="number" value={value} onChange={valueChange} />
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Specification</label>
            </div>
            <textarea
              type="text"
              value={specification}
              onChange={specificationChange}
            />
          </div>
          <div className="form-width">
            <div>
              <label>Details</label>
            </div>
            <textarea type="text" value={details} onChange={deatailsChange} />
          </div>
          <div className="form-width" style={{display:'flex',alignContent:'center'}}>
           
      <label className="imglabel" htmlFor="fileInput">
      
      <span className='writeIcon'>Add Image{file && ( 
      <img className="imgUpload" src={URL.createObjectURL(file)} alt="" />
      )}
            </span>
          </label>
         
            <input type='file' id="fileInput" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} ref={fileInput} />
            <div  className="uploadInfo_container">
            <button className="imgUpload_btn" onClick={handleClick} disabled={uploading}>
            {
              uploading
              ? <Spinner 
                  animation="border" role="status">
                <span className="visually-hidden">Uploading...</span>
              </Spinner>
              : 'Upload'
            }</button>
            <span className="upload_info">Upload Image before Saving the details</span>
            </div>
           
          </div>
        </div>
        <div className="center-button">
          <button
          className="save_btn"
            type="submit"
            disabled={loading}>
            {
              loading
              ? <Spinner 
                  animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              : 'SAVE'
            }
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateProfile;
