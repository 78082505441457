import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import S3FileUpload from "react-s3";
import { uploadFile } from "react-s3";
import "./index.css";

import { Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const Products = () => {
  const [category, setCategory] = useState("");
  const [productName, setProductName] = useState();
  const [mrp, setMrp] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [specification, setSpecification] = useState("");
  const [description, setDescription] = useState("");
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [images, setImages]=useState([])
  const [deliveryOption, setDeliveryOption] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [promocode, setPromocode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [loading, setLoading] = useState(false);
  const [enableAddButton, setEnableAddButton] = useState(false);

  const userID = useSelector((state) => state.login.user.id);
  const name = useSelector((state) => state.login.user.name);
  const history = useHistory();

  let imageButtonOne = useRef();
  let imageButtonTwo = useRef();
  let imageButtonThree = useRef();

  const checkMandatoryFields = () => {
    if (category && productName && mrp && salePrice && specification
      && description && (imageOne || imageTwo || imageThree) && deliveryOption
      && availableQuantity && supplierName) {
        if (!(discountAmount && !promocode)) {
          setEnableAddButton(true);
        } else {
          setEnableAddButton(false);
        }
    } else {
      setEnableAddButton(false);
    }
  }

  useEffect(() => {
    checkMandatoryFields();
  }, [checkMandatoryFields]);

  const triggerImageButton = (buttonName) => {
    switch(buttonName) {
      case 'imageButtonOne':
        imageButtonOne.click();
        break;
      case 'imageButtonTwo':
        imageButtonTwo.click();
        break;
      case 'imageButtonThree':
        imageButtonThree.click();
        break; 
      default:
        break;    
    }
  }

  const categoryChange = (e) => {
    setCategory(e.target.value);
  };
  const productNameChange = (e) => {
    setProductName(e.target.value);
  };
  const mrpChange = (e) => {
    setMrp(e.target.value);
  };
  const salePriceChange = (e) => {
    setSalePrice(e.target.value);
  };
  const specificationChange = (e) => {
    setSpecification(e.target.value);
  };
  const discountAmountChange = (e) => {
    setDiscountAmount(e.target.value);
  };
  const promocodeChange = (e) => {
    setPromocode(e.target.value);
  };
  const supplierNameChange = (e) => {
    setSupplierName(e.target.value);
  };
  const descriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const imageOneChange = (e) => {
    setImageOne(e.target.files[0]);
    setImages([...images, e.target.files[0]]);
  };

  const imageTwoChange = (e) => {
    setImageTwo(e.target.files[0]);
    setImages([...images, e.target.files[0]]);
  };

  const imageThreeChange = (e) => {
    setImageThree(e.target.files[0]);
    setImages([...images, e.target.files[0]]);
  };

  const deliveryOptionChange = (e) => {
    setDeliveryOption(e.target.value);
  };

  const availableQuantityChange = (e) => {
    setAvailableQuantity(e.target.value);
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const saveProduct = (avatarImages) => {
    const payload = {
      category,
      productName,
      mrp,
      salePrice,
      specification,
      description,
      avatar: avatarImages,
      supplierName,
      deliveryOption,
      availableQuantity,
      discountAmount,
      promocode,
      id: localStorage.getItem('sellerId')
    };

    axios
    .post(process.env.REACT_APP_API_ENDPOINT + "/api/products", payload)
    .then((res) => {
      setLoading(false);
      history.push("/products-list");
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }

  const productSubmit = async (e) => {
   
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const avatarImages = [];

    for (let i = 0; i < images.length; i++) {
      const s3imageData = await S3FileUpload.uploadFile(images[i], config);
      const imageFileName = s3imageData.key.slice(s3imageData.key.lastIndexOf('/') + 1, s3imageData.key.length);
      avatarImages.push(imageFileName);
    }
    const sellerId = localStorage.getItem('sellerId');
    
    
    const packageApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/package");
      const packageData = packageApi.data;
  
      let packageData1=packageData.filter((data) => data.userId === sellerId)

    
      if(packageData1.length>0){
    
          if (packageData1[packageData1.length-1].package === "level-3") {
               if( new Date(packageData1[packageData1.length-1].expiryDate)>new Date())
               {
                saveProduct(avatarImages);
               }
               else{
                 alert("Your plan expired");
                 setLoading(false);
               }
                
           
          } else if (packageData1[packageData1.length-1].package  === "level-2" && new Date(packageData1[packageData1.length-1].expiryDate)>new Date()) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/products?userId=' + sellerId)
            .then(response => {
              let productCount=response.data.length;
              if(productCount<50)
              {
                saveProduct(avatarImages);
              }
            else{
                alert("You have already 50 products");
                setLoading(false);
            }
     
    }).catch(err => console.log(err));
          } else if (packageData1[packageData1.length-1].package === "level-1" && new Date(packageData1[packageData1.length-1].expiryDate)>new Date()) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/products?userId=' + sellerId)
            .then(response => {
              let productCount=response.data.length;
              if(productCount<10)
              {
                saveProduct(avatarImages);
              }
            else{
                alert("You have already  10 products")
                setLoading(false)
            }
     
    }).catch(err => console.log(err));
        }
        else{
          alert("Your plan was expired")
          setLoading(false)
        }
        

      }
      else{
        alert("Currently you don't have any plan");
        setLoading(false)
      }

    
  };

  const dataURLToBlob = (dataurl) => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  return (
    <div className="Products">
      <div className="add-product-title">Add Product</div>
      <form onSubmit={productSubmit} encType="multipart/form-data">
        <div className="form-width-2">
          <div className="form-width">
            <label className="label-container-product">Category</label>
            <select
              className="cornerco-form-theme force-margin"
              style={{width: '80%', padding: '0px'}}
              name="category"
              value={category}
              onChange={categoryChange}>
              <option value=""></option>
              <option value="vitamins">Vitamins</option>
              <option value="book">Books</option>
              <option value="oil">Essential Oils</option>
              <option value="ourpicks">Our Picks</option>
              <option value="sale">Sale</option>
              <option value="supplements">Supplements</option>
              <option value="merchandise">Merchandise</option>
              <option value="cosmetics">Cosmetics</option>
              <option value="fitness">Fitness</option>
            </select>
          </div>
          <div className="form-width">
            <label className="label-container-product">Product Name</label>
            <input
              className="force-margin"
              type="text"
              value={productName}
              onChange={productNameChange}
            />
          </div>
        </div>

        <div className="_formField3_">
          <div className='_innerForm3_'>
          <div className="formWidth">
            <label className="label-container-product">MRP</label>
            <input className='_mrp_ cornerco-form-theme force-margin' type="number" value={mrp} onChange={mrpChange} />
          </div>
          
          
          <div className="formWidth">
            <label className="label-container-product">Sale Price</label>
            <input className='_sale_ cornerco-form-theme force-margin' type="number" value={salePrice} onChange={salePriceChange} />
          </div>
          </div>
          <div className='_form3Right_'>
          <div className="form-width">
            <label className="label-container-product">Specification</label>
            <input
              className="force-margin"
              type="text"
              value={specification}
              onChange={specificationChange}
            />
          </div>
          </div>
        </div>

        <div className="_formField5_">
          <div className='_innerForm5_'>
          <div className="form-width">
            <label className="label-container-product">Description</label>
            <textarea
              className="cornerco-form-theme force-margin"
              style={{
                minHeight: 120,
                maxHeight: 200,
                maxWidth: '80%'
              }}
              type="text"
              value={description}
              onChange={descriptionChange}
            />
          </div>
          </div>
          <div className="_inner2Form5_">
            <div className="_formWidth_">
              <label className="label-container-product">Image1</label>
              <input
                ref={ref => imageButtonOne = ref}
                type="file"
                className='_formImg_'
                onChange={imageOneChange}
                accept="image/*"
              />
              <span
                onClick={() => triggerImageButton('imageButtonOne')}
                className="image-preview">
                  {
                    imageOne &&
                      <img
                        className="image-previewer"
                        src={ URL.createObjectURL(imageOne) }
                        alt="image1"/>
                  }
                </span>
            </div>
            <div className="_formWidth_">
              <label className="label-container-product">Image2</label>
              <input 
                ref={ref => imageButtonTwo = ref}
                type = "file" 
                className='_formImg_'
                onChange = {imageTwoChange}
                accept="image/*"
              />
              <span
                onClick={() => triggerImageButton('imageButtonTwo')}
                style={{marginLeft: '6%'}}
                className="image-preview">
                  {
                    imageTwo &&
                      <img
                        className="image-previewer"
                        src={ URL.createObjectURL(imageTwo) }
                        alt="image2"/>
                  }
                </span>
            </div>
            <div className="_formWidth_">
              <label className="label-container-product">Image3</label>
              <input 
                ref={ref => imageButtonThree = ref}
                className='_formImg_'
                type = "file" 
                onChange = {imageThreeChange}
                accept="image/*"
              />
              <span
                onClick={() => triggerImageButton('imageButtonThree')}
                style={{marginLeft: '11%'}}
                className="image-preview">
                  {
                    imageThree &&
                      <img
                        className="image-previewer"
                        src={ URL.createObjectURL(imageThree) }
                        alt="image3"/>
                  }
                </span>
            </div>
          </div>
        </div>
        <div className="form-width-2">
          <div className="form-width">
            <label className="label-container-product">Delivery Option</label>
            <input
              className="force-margin"
              type="text"
              value={deliveryOption}
              onChange={deliveryOptionChange}
            />
          </div>
          <div className="form-width">
            <label className="label-container-product">Available Quantity</label>
            <input
              className="force-margin"
              type="number"
              value={availableQuantity}
              onChange={availableQuantityChange}
            />
          </div>
        </div>

        <div className="_formField3_">
          <div className='_innerForm3_'>
          <div className="formWidth">
            <label className="label-container-product promocode">
              Discount Amount
            </label>
            <input className='_mrp_ cornerco-form-theme force-margin' type="text" value={discountAmount} onChange={discountAmountChange} />
          </div>
          
          
          <div className="formWidth">
            <label className="label-container-product promocode">
              Promo Code
              {
                discountAmount
                && <span className="promocode-star">*</span>
              }
            </label>
            <input
              disabled={!discountAmount}
              className='_sale_ cornerco-form-theme force-margin' 
              type="text" value={promocode} 
              onChange={promocodeChange} />
          </div>
          </div>
          <div className='_form3Right_'>
          <div className="form-width">
            <label className="label-container-product">Supplier Name</label>
            <input
              className="force-margin"
              type="text"
              value={supplierName}
              onChange={supplierNameChange}
            />
          </div>
          </div>
        </div>
       
        <button
          type="submit"
          className="product-button"
       
          disabled={!enableAddButton}
          >
          {
            loading
            ? <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            : 'ADD'
          }
        </button>
      </form>
    </div>
  );
};

export default Products;
