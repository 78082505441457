import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import './index.css';

export default class MyOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: []
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => {
    const sellerId = localStorage.getItem('sellerId');
    //alert(process.env.REACT_APP_API_ENDPOINT + "/api/transaction?userId=" + sellerId)
    axios.get(
      process.env.REACT_APP_API_ENDPOINT + "/api/transaction?userId=" + sellerId
    ).then((orderApi) => {
      const orderData = orderApi.data;
      //alert(JSON.stringify(orderData))
      let individualSupplier = [];
      let supp = [];
      orderData.forEach((data) => {
          individualSupplier.push(data.productDetails);
      });
      individualSupplier.map(data => {
        data.forEach((d) => {
          supp.push(d)
        })
      });
      this.setState({orderList: supp});
    }).catch(err => {

    });
  }

  viewProduct = (orderId, customerName, total, purchasedOn, deliveryOption, address) => {
    this.props.history.push(`/order-details`, {
      orderId,
      customerName,
      total,
      purchasedOn,
      deliveryOption,
      address
    });
  }

  render() {
    return (
      <div className="orders-container">
        <div className="row">
          <div className="col-md-6 product-heading-nav">
            My Orders
          </div>
        </div>
        <div className="row">
          <Table className="product-table" hover>
            <thead style={{height: '40px', fontSize: '15px'}}>
              <tr>
                <th>No</th>
                <th style={{width: '15vw'}}>Order ID</th>
                <th style={{width: '25vw'}}>Customer Name</th>
                <th style={{whiteSpace: 'nowrap'}}>Invoice Amount</th>
                <th style={{width: '22vw'}}>Purchased On</th>
                <th style={{whiteSpace: 'nowrap'}}>View</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.orderList.map((orderData, index) => {
                  return (
                    <tr key={orderData._id}>
                      <td>{index+1}</td>
                      <td>{orderData.orderId}</td>
                      <td>{orderData.customerName}</td>
                      <td>{orderData.pTotal}</td>
                      <td style={{whiteSpace: 'nowrap'}}>{orderData.purchasedOn ? moment(new Date(orderData.purchasedOn)).format('Do-MMM h:mmA') : ''}</td>
                      <td>
                        <i
                          onClick={() => this.viewProduct(
                            orderData.orderId,
                            orderData.customerName,
                            orderData.pTotal,
                            orderData.purchasedOn,
                            orderData.deliveryOption,
                            orderData.address
                          )}
                          className="fa fa-eye fa-custom-view"
                          aria-hidden="true"></i>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}