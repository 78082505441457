import { useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./index.css";

const CreateQuotes = (props) => {
  const [quote, setQuote] = useState("");

  const tags = [
    { label: "Relationships", value: 1 },
    { label: "Depression & anxiety", value: 2 },
    { label: "Career & workplace", value: 3 },
    { label: "Investment & business", value: 4 },
    { label: "Lockdown", value: 5 },
    { label: "Spiritual beliefs", value: 6 },
    { label: "Health & Fitness", value: 7 },
    { label: "Personal development", value: 8 },
    { label: "Sexual health", value: 9 },
    { label: "Parenting", value: 10 },
    { label: "Abuse", value: 11 },
    { label: "Goals", value: 12 },
    { label: "Studying", value: 13 },
    { label: "Self-harm & suicide", value: 14 },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState("");

  const quoteChange = (e) => {
    setQuote(e.target.value);
  };

  const addQuote = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_ENDPOINT + "/api/quote",
        data: {
          quote: quote,
          // tags: selectedOptions,
          category: selectedLabels,
        },
      }).then(() => {
        setQuote("");
        props.getQuotes();
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    setSelectedOptions(e);
    setSelectedLabels(
      e
        .map((f) => {
          return f.label;
        })
        .join()
    );
  };

  return (
    <div className="CreateQuotes">
      <h5 style={{ marginBottom: 15 }}>Create Quote</h5>
      <form>
        <textarea
          type="text"
          placeholder="Enter Quote Of The Day"
          value={quote}
          onChange={quoteChange}
        />
        <div style={{ width: "50%", margin: "1.5rem 0" }}>
          <Select
            isMulti
            options={tags}
            value={selectedOptions}
            onChange={handleChange}
          />
        </div>
        <button type="submit" onClick={addQuote}>
          ADD
        </button>
      </form>
    </div>
  );
};

export default CreateQuotes;
