import { NavLink } from "react-router-dom";
import './index.css';
import logo from "../../Images/logo.png";


const SupplierNavbar = () => {
  const userEmail = localStorage.getItem('email');
  let userName = localStorage.getItem('userName');
  userName = userName === undefined || userName === 'undefined' ? '' : userName;
  return (
    <div className="Navbar">
      <div className="side-navbar-supplier">
        <img className="navbar-logo" src={logo} alt="logo-missing" />
        <div className="userinfo-container">
          <span>{userName || ''}</span>
          <span>{userEmail || ''}</span>
        </div>
        <div className="navlinks-container">
          <div>
            <NavLink
              exact
              to="/dashboard"
              activeClassName="active"
              className="nav-link"
            >
              Dashboard
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/myorders"
              activeClassName="active"
              className="nav-link"
            >
              My Orders
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/products-list"
              activeClassName="active"
              className="nav-link"
            >
              Products
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/ads"
              activeClassName="active"
              className="nav-link"
            >
              Website Ad
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/packages"
              activeClassName="active"
              className="nav-link"
            >
              Packages
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierNavbar;
