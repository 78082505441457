import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "./index.css";

const AddClips = (props) => {
  const [clip, setClip] = useState("");
  const [title, setTitle] = useState("");

  const clipChange = (e) => {
    setClip(e.target.value);
  };
  const titleChange = (e) => {
    setTitle(e.target.value);
  };

  const tags = [
    { label: "Relationships", value: 1 },
    { label: "Depression & anxiety", value: 2 },
    { label: "Career & workplace", value: 3 },
    { label: "Investment & business", value: 4 },
    { label: "Lockdown", value: 5 },
    { label: "Spiritual beliefs", value: 6 },
    { label: "Health & Fitness", value: 7 },
    { label: "Personal development", value: 8 },
    { label: "Sexual health", value: 9 },
    { label: "Parenting", value: 10 },
    { label: "Abuse", value: 11 },
    { label: "Goals", value: 12 },
    { label: "Studying", value: 13 },
    { label: "Self-harm & suicide", value: 14 },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLabels,setSelectedLabels]=useState('')

  const handleChange = (e) => {
    setSelectedOptions(e);
    setSelectedLabels(  
      e.map((f) => {
        return  f.label
      }).join()
    )
  };

  const addClip = async (e) => {
    e.preventDefault();
    try {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_ENDPOINT + "/api/clip",
        data: {
          url: clip,
          title: title,
          category:selectedLabels
        },
      }).then(() => {
        setClip("");
        setTitle("");
        setSelectedLabels([])
        props.getClipData();
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => { }, []);

  return (
    <div className="Craete-Clips">
      <span>Add New Clip</span>
      <form>
        <input
          type="text"
          placeholder="Enter URL"
          value={clip}
          onChange={clipChange}
        />
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={titleChange}
        />

        <Select
          isMulti
          options={tags}
          value={selectedOptions}
          onChange={handleChange}
        />

        <button onClick={addClip}>Upload</button>
      </form>
    </div>
  );
};

export default AddClips;
