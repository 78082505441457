import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import "./index.css";

const ListProfile = () => {
  const [profileDataGet, setProfileDataGet] = useState("");
  const [profileDataLength, setProfileDataLength] = useState("");
  const userId = localStorage.getItem('userId');
  const history = useHistory();
  // console.log('userId: ', userId);
  const url = process.env.REACT_APP_API_ENDPOINT + "/api/profile";

  const getProfiles = async () => {
    try {
      const profiles = await axios.get(url);
      const profileData = profiles.data;
      let ProfileDataGet=profileData.filter(data => (data.id === userId))
      
      setProfileDataGet(ProfileDataGet);
      setProfileDataLength(ProfileDataGet.length + 2)
     
     
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(async () => {
    getProfiles();
  }, [setProfileDataGet]);

  const createprofile = ( profileNum) => {

    history.push({
      pathname: "/create-profile"+ "/"+ profileNum,
      
  });
  };

  const editProfile = (id, userId,profileNum) => {
   
    history.push({
      pathname: "/profile-edit/" + id + "/"+ profileNum ,
      state: {
        userId
      }
    });
  };
  return (
    <div>
      <div className="CoachProfile">
        <div>
          <div className="card card-one">
            {profileDataGet[0] === undefined ? (
              <h4 onClick={()=>createprofile(profileDataLength)}>ADD +</h4>
            ) : (
              <>
                <span className="profile-title">{profileDataGet[0].title}</span>
                <span className="profile-value">{profileDataGet[0].value}</span>
              </>
            )}
          </div>
          {profileDataGet[0] === undefined ? (
            <></>
          ) : (
            <div
              className="edit"
              onClick={() => editProfile(profileDataGet[0]._id, profileDataGet[0].id,0)}
            >
              <i className="fa fa-pencil profile-edit" aria-hidden="true"></i>
            </div>
          )}
        </div>
        <div>
          <div className="card card-two">
            {profileDataGet[1] === undefined ? (
              <h4 onClick={()=>createprofile(profileDataLength)}>ADD +</h4>
            ) : (
              <>
                <span className="profile-title">{profileDataGet[1].title}</span>
                <span className="profile-value">{profileDataGet[1].value}</span>
              </>
            )}
          </div>
          {profileDataGet[1] === undefined ? (
            <></>
          ) : (
            <div className="edit" onClick={() => editProfile(profileDataGet[1]._id, profileDataGet[0].id,1)}>
              <i className="fa fa-pencil profile-edit" aria-hidden="true"></i>
            </div>
          )}
        </div>
        <div>
          <div className="card card-three">
            {profileDataGet[2] === undefined ? (
              <h4 onClick={()=>createprofile(profileDataLength)}>ADD +</h4>
            ) : (
              <>
                <span className="profile-title">{profileDataGet[2].title}</span>
                <span className="profile-value">{profileDataGet[2].value}</span>
              </>
            )}
          </div>
          {profileDataGet[2] === undefined ? (
            <></>
          ) : (
            <div
              className="edit"
              onClick={() => editProfile(profileDataGet[2]._id, profileDataGet[0].id,2)}
            >
              <i className="fa fa-pencil profile-edit" aria-hidden="true"></i>
            </div>
          )}
        </div>
      </div>
      <a style={{marginLeft: 25}} href={`https://coachprofilepdfs.s3.ap-southeast-2.amazonaws.com/${userId}-plans.pdf`}>Download PDF</a>
    </div>
  );
};

export default ListProfile;

// {
//   profileDataThree === undefined ? (
//     <h4 onClick={createprofile}>ADD +</h4>
//   ) : profileDataThree.id === userId ? (
//     <h4>{profileDataThree.title}</h4>
//   ) : (
//     <></>
//   );
// }