import React from 'react';
import { Table } from 'react-bootstrap';
import './index.css';
import axios from "axios";
import * as moment from 'moment';

export default class MyStudents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentInfoList: []
    };
  }

  componentDidMount() {
    this.getStudentInfos();
  }

  getStudentInfos = () => {
    const coachId = localStorage.getItem('coachId');
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/student/' + coachId)
    .then(response => {
      this.setState({studentInfoList: response.data});
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <div className="student-container">
        <div className="row">
          <div className="col-md-6 blog-heading-nav">
            My Students
          </div>
          {/* <div className="col-md-6 blog-list-nav">
            <button
              onClick={() => this.props.history.push('/create-student')}
              className="blog-button">Add Student</button>
          </div> */}
        </div>
        <div className="row">
          <Table className="blog-table" hover>
            <thead>
              <tr>
                <th>No</th>
                <th style={{width: '40vw'}}>Student Name</th>
                <th style={{width: '20vw'}}>Plan Name</th>
                <th style={{width: '20vw'}}>Package Value</th>
                <th style={{width: '20vw'}}>Purchased On</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.studentInfoList.map((stdData, index) => {
                  return (
                    <tr key={stdData._id}>
                      <td>{index + 1}</td>
                      <td>{stdData.userName}</td>
                      <td>{stdData.planName}</td>
                      <td>{stdData.invoiceAmount}</td>
                      <td>{moment(new Date(stdData.createdAt)).format('D/M/Y, h:mm:ss a')}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

