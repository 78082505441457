import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import './index.css';

export default class CoachRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersList: []
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/users?coachRequest=true')
        .then(response => {
            this.setState({usersList: response.data});
        }).catch(err => console.log(err));
    }

    handleCoachRequest = (coachRequest, userId) => {
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/coach-request/${userId}?coachRequest=${coachRequest}`)
        .then(response => {
            this.getUsers();
        }).catch(err => console.log(err));
    }

    render() {
        return (
            <div className="cr-container">
                <div className="row" style={{marginBottom: 15}}>
                    <strong>Coach Requests</strong>
                </div>
                <div className="row">
                    <Table className="product-table" hover>
                        <thead className="thead-cr" style={{height: '40px', fontSize: '14px'}}>
                            <tr className="tr-cr-head">
                                <th>No</th>
                                <th>Name</th>
                                <th style={{width: '20vw'}}>Email</th>
                                <th style={{width: '13.4vw'}}>Category</th>
                                <th style={{width: '13.4vw'}}>Sub Category</th>
                                <th>Postcode</th>
                                <th style={{width: '13vw'}}>Requested On</th>
                                <th colSpan="2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.usersList.map((userData, index) => {
                                return (
                                    <tr key={userData._id} className="tr-cr">
                                        <td>{index+1}</td>
                                        <td>{userData.userName}</td>
                                        <td>{userData.email}</td>
                                        <td>{userData.category || '--'}</td>
                                        <td>{userData.subCategory || '--'}</td>
                                        <td>{userData.postcode || '--'}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>{userData.createdAt ? moment(new Date(userData.createdAt)).format('Do-MMM-Y h:mmA') : ''}</td>
                                        <td
                                            onClick={() => this.handleCoachRequest(true, userData._id)}
                                            className="cr-yes">Yes</td>
                                        <td
                                            onClick={() => this.handleCoachRequest(false, userData._id)}
                                            className="cr-no">No</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}