import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";

import "./index.css";

const DashBoard = () => {
  const [orderCount, setOrderCount] = useState('');
  const [productCount, setProductCount] = useState('');
  const [userCount, setUsersCount] = useState('');
  const [trainerCount, setTrainersCount] = useState('');
  const [blogCount, setBlogsCount] = useState('');
  const [quotesCount, setQuotesCount] = useState('');
  const [orderDetails, setOrderDetails] = useState([]);
  let no = 0;

  useEffect(() => {
    orders();
    products();
    blogs();
    trainers();
    users();
    quotes();
  }, [])

  const orders = async () => {
    const orderApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/transaction");
    const orderData = orderApi.data;
    let individualSupplier = [];
    let supp = [];
    orderData.forEach((data) => {
      individualSupplier.push(data.productDetails);
    });
    individualSupplier.map((data) => {
      data.forEach((d) => {
        supp.push(d);
      });
    });
    let count = 0;
    setOrderDetails(supp);
    supp.map(data => {
      count += 1
    })
    setOrderCount(count);
  };

  const products = async() => {
    let count = 0;
    const productsApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/products");
    const productData = productsApi.data;
    productData.map((data) => {
      count += 1
    });
    setProductCount(count);
  }

  const users = async () => {
    let count = 0;
    const usersApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/users");
    const userData = usersApi.data;
    console.log(userData, "user");
    userData.map((data) => {
      count += 1;
    });
    setUsersCount(count);
  };

  const trainers = async () => {
    let count = 0;
    const trainersApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/users");
    const trainerData = trainersApi.data;
    const trainerFilter = trainerData.filter(data => data.userType === 'coach');
    console.log(trainerFilter, "trainer");
    trainerFilter.map((data) => {
      count += 1;
    });
    setTrainersCount(count);
  };

  const blogs = async () => {
    let count = 0;
    const blogsApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/blog");
    const blogData = blogsApi.data;
    blogData.map((data) => {
      count += 1;
    });
    setBlogsCount(count);
  };

  const quotes = async () => {
    let count = 0;
    const quotesApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/quote");
    const quoteData = quotesApi.data;
    console.log(quoteData, "quote");
    quoteData.map((data) => {
      count += 1;
    });
    setQuotesCount(count);
  };

  return (
    <div className="DashBoard">
      <div className="cards">
        <div className="card card-one">
          <div className="name">Total Orders</div>
          <div className="value">{orderCount}</div>
        </div>
        <div className="card card-two">
          <div className="name">Total Products</div>
          <div className="value">{productCount}</div>
        </div>
        <div className="card card-three">
          <div className="name">Total Users</div>
          <div className="value">{userCount}</div>
        </div>
        <div className="card card-four">
          <div className="name">Total Trainers</div>
          <div className="value">{trainerCount}</div>
        </div>
        <div className="card card-five">
          <div className="name">Total Blogs</div>
          <div className="value">{blogCount}</div>
        </div>
        <div className="card card-six">
          <div className="name">Total Quotes</div>
          <div className="value">{quotesCount}</div>
        </div>
      </div>
      {/* <div className="dashboard-table" style={{
        overflowY: 'auto',
        height: '350px'
      }}>
        <h3>Recent Orders</h3>
        <table className="table">
          <tr>
            <th className="table-head">No</th>
            <th className="table-head">Order ID</th>
            <th className="table-head">Trainer Name</th>
            <th className="table-head">Invoice Amount</th>
            <th className="table-head">Purchased On</th>
          </tr>
          {orderDetails.slice(0, 3).map((data) => (
            <tr className="table-even">
              <td className="table-body" data-label="No">
                {(no += 1)}
              </td>
              <td className="table-body" data-label="Order ID">
                {"CO8456" + no}
              </td>
              <td className="table-body" data-label="Trainer Name">
                Jhon Potter
              </td>
              <td className="table-body" data-label="Invoice Amount">
                {data.pTotal}
              </td>
              <td className="table-body" data-label="Purchased On">
                {data.purchasedOn}
              </td>
            </tr>
          ))}
        </table>
      </div> */}
    </div>
  );
};

export default DashBoard;
