import { useState,useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";

import {
  getForgetUser,
  getForgetUserId,
  getUser,
} from "../Redux/reduxLogin/loginActions";

import logo from "../../Images/logo.png";
import WhiteLogo from "../../Images/cornerco-logo.png";

import "./index.css";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const [dashboardType, setDashboardType] = useState("");
  const [dashboardEmail, setDashboardEamil] = useState("");
  const [dashboardPassword, setDashboardPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalidCred, setInvalidCred] = useState(false);
  // const [filtered, setFiltered] = useState();
  // const [userId, setUserID] = useState();
useEffect(()=>{
  // localStorage.setItem('userType','');
},[])
  const dispatch = useDispatch();
  const history = useHistory();

  const dashboardTypeChange = (e) => {
    setDashboardType(e.target.value);
  };

  const dashboardEmailChange = (e) => {
    setInvalidCred(false);
    setDashboardEamil(e.target.value);
  };

  const dashboardPasswordChange = (e) => {
    setInvalidCred(false);
    setDashboardPassword(e.target.value);
  };

  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };

  const adminSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const payload = {
        email: dashboardEmail,
        password: dashboardPassword
      };

      let adminLoginData;
      let userData = null;
      let token;
      try {
        adminLoginData = await axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/users/login", payload);
        adminLoginData = adminLoginData.data;
        userData = adminLoginData.userData;
        token = adminLoginData.token;
        localStorage.setItem('token', token);
      } catch (err) {
        setLoading(false);
        console.log('invalid credentials.');
      }
      setLoading(false);
      if (userData) {
        if (userData.userType === "coach") {
          console.log("coach");
          dispatch(
            getUser(
              dashboardEmail,
              dashboardPassword,
              userData.userType,
              userData._id,
              userData.userName
            )
          );
          localStorage.setItem('userType', userData.userType);
          localStorage.setItem('coachId', userData._id);
          localStorage.setItem('userId', userData._id);
          localStorage.setItem('email', userData.email);
          localStorage.setItem('userName', userData.userName);
          window.location.href=window.location.origin+'/list-blogs';
        } else if (userData.userType === "admin") {
          console.log("admin");
          dispatch(
            getUser(
              dashboardEmail,
              dashboardPassword,
              userData.userType,
              userData._id,
              userData.userName
            )
          );
          localStorage.setItem('userType', userData.userType);
          localStorage.setItem('email', userData.email);
          localStorage.setItem('userName', userData.userName);
          localStorage.setItem('userId', userData._id);
          window.location.href=window.location.origin+'/dashboard';
        } else if (userData.userType === "seller") {
          console.log("seller");
          dispatch(
            getUser(
              dashboardEmail,
              dashboardPassword,
              userData.userType,
              userData._id,
              userData.userName
            )
          );
          localStorage.setItem('userType', userData.userType);
          localStorage.setItem('email', userData.email);
          localStorage.setItem('userName', userData.userName);
          localStorage.setItem('sellerId', userData._id);
          localStorage.setItem('userId', userData._id);
          window.location.href=window.location.origin+'/dashboard';
        } 
      } else if (userData && userData.email === dashboardEmail) {
        console.log("email only match");
      } else if (userData && userData.password === dashboardPassword) {
        console.log("password only match");
      } else {
        setInvalidCred(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const forgetPassword = async () => {
    history.push("/enter-email");
  };

  const userTypeLS = localStorage.getItem('userType');
  if (userTypeLS === 'admin') {
    return <Redirect to="/dashboard" />;
  } else if (userTypeLS === 'coach') {
    return <Redirect to="/coach-profile" />;
  } else if (userTypeLS === 'seller') {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="Login">
      <div className="logo">
        <img src={logo} className="login-logo" alt="logo" />
        <h2>Cornerco | <span style={{color: 'white'}}>Admin Panel</span></h2>
      </div>
      <div className="login-form">
        <img src={WhiteLogo} className="login-white-logo"></img>
        <form onSubmit={adminSubmit}>
          {/* <div >
            <input type="radio" 
                value="cornercoadmin"
                name = "adminpannel"
                onChange = {dashboardTypeChange } />Conerco Admin
            <input type="radio" 
                value="coach" 
                name = "adminpannel"
                onChange = {dashboardTypeChange } />I am coach
            <input type="radio" 
                value="seller" 
                name = "adminpannel"
                onChange = {dashboardTypeChange } />I am Seller
            </div> */}
          <p>{error}</p>
          <div>
            <label>Email</label>
            <input
              type="email"
              onChange={dashboardEmailChange}
              className="login-input"
              required={true}
            />
          </div>
          <div className="check">
            <div className="inside"></div>
          </div>
          <div style={{position: 'relative'}}>
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              onChange={dashboardPasswordChange}
              className="login-input"
              required={true}
            />
            <i
              className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
              onClick={showPasswordClick}
              aria-hidden="true"
            ></i>
            <div className="check">
              <div className="inside"></div>
            </div>
            {
              invalidCred && <span className="invalid-cred">Invalid Credentials!</span>
            }
          </div>
          <h5 className="forget-pass" onClick={forgetPassword}>Forget Password?</h5>
          <div style={{marginTop: 80}} className="d-flex justify-content-center align-items-center">
            <button
              type="submit"
              className="login-button"
              disabled={loading}>
              {
                loading
                ? <Spinner 
                    animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                : 'Login'
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
