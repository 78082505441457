import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import "./index.css";
import { useSelector } from "react-redux";

const EnterOtp = () => {
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const forgetId = useSelector((state) => state.login.forget.id);
  const otpChange = (e) => {
    setOtp(e.target.value);
  };
  const otpSubmit = async (e) => {
    e.preventDefault();
    try {
 
      const otpGet = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/${forgetId}`
        
      );
      const otpData = otpGet.data;
      console.log(otpData.otp);
      console.log(otp)

      if (otpData.otp == +otp) {
        history.push("/reset-password");
      } else {
        setOtp("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="EnterOtp">
      <form onSubmit={otpSubmit}>
        <h5 className="otp_sent">An OTP has been sent to your registered e-mail, check and enter to reset your password.</h5>
        <input className="_inputbox_"
          type="number"
          placeholder="Enter OTP"
          value={otp}
          onChange={otpChange}
        />
        <button type='submit' >Verify</button>
      </form>
    </div>
  );
};

export default EnterOtp;
