import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import './index.css';
import {
  getForgetUser,
  getForgetUserId,
  getUser,
} from "../Redux/reduxLogin/loginActions";
import logo from "../../Images/logo.png";
import WhiteLogo from "../../Images/cornerco-logo.png";

function ForgetPasswordEmail() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(false);
  const history = useHistory();

  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const emailSendHandler = async (e) => {
    e.preventDefault();
console.log(email)
    if(email!=''){
        const num = Math.floor(1000 + Math.random() * 9000);
        
        try {
        
          try {
            const getuser = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/users");
            const userData = getuser.data;
            const userId = userData.filter((datas) => datas.email === email);
            console.log(userId.length)
            userId.map((id) => {
              dispatch(getForgetUserId(id._id));
            });
    
            userId.map((data) => {
              dispatch(getForgetUser(data.email, data.otp, data._id));
            });
            if (userId.length != 0) {
              history.push("/enter-otp");
              setMessage(false);
              await axios({
                method: "post",
                url: process.env.REACT_APP_API_ENDPOINT + "/api/usersOtp",
                data: {
                  otp: num,
                  email: email,
                },
              });
            } else {
              setMessage(true);
            }
          } catch (err) {
            console.log(err);
            console.log("error");
          }
        } catch (err) {
          console.log(err);
        }
    }
    else{
        setMessage(true);

    }
 
  };

  return (
    <>
    <div className="Login">
      <div className="logo">
        <img src={logo} className="login-logo" alt="logo" />
        <h2>Cornerco | <span style={{color: 'white'}}>Admin Panel</span></h2>
      </div>
      <div className="forget-form">
        <img src={WhiteLogo} className="login-white-logo"></img>
        <form onSubmit={emailSendHandler} className='_forgetForm_'>
        
        <p className="forget_password_text">Forget Password</p>
        <p className="forget_password_text">We will send OTP to registered email to reset your password</p>
        <label>Email</label>
        <input type="email" value={email} onChange={emailChange} className='_inputEmail_' />
        {message && <p className="email_warning">Please enter a valid email address</p>}
        <button type="submit">Send OTP</button>
      </form>
      </div>
    </div>
    {/* <div className='_forgetPassword_'>
        <div className='_formCover_'>
      <form onSubmit={emailSendHandler} className='_forgetForm_'>
        
        <p>Forget Password</p>
        <p>We will send OTP to registered email to reset your password</p>
        <label>Email</label>
        <input type="email" value={email} onChange={emailChange} className='_inputEmail_' />
        {message && <p className="email_warning">Please enter a valid email address</p>}
        <button type="submit">Send OTP</button>
      </form>
      </div>
    </div> */}
    </>
  );
}

export default ForgetPasswordEmail;
