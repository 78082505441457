import './index.css';

const PageLoading = () => {
    return (
        <div className="loader-box-container">
            <div className="loader-box">
            </div>
        </div>
    );
}

export default PageLoading;