import { useEffect } from 'react';
import axios from 'axios';

import './index.css';
import { useHistory } from 'react-router';
import moment from 'moment';

const ListQuotes = ({ quoteData, getQuotes }) => {
  const history = useHistory();

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/quote";

  const deleteQuote = async (id) => {
    try {
      await axios.delete(url + "/" + id);
      getQuotes();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async() => {
    getQuotes();
  }, []);

  const editQuote = (id) => {
    history.push("/quote-edit/" + id);
  };

    return (
      <div className="ListQuotes">
        {quoteData.map((quote) => (
          <div className="list-quotes-grid">
            <div className="quote-info-container">
              <span className="quote-title">{quote.quote}</span>
              <span className="quote-date">
                {
                  quote.createdAt
                  ? moment(quote.createdAt).format('LLL')
                  : ''
                }
              </span>
            </div>

            <div className="quotes-icon">
              <i
                className="fa fa-pencil-square-o edit"
                aria-hidden="true"
                onClick={() => editQuote(quote._id)}
              ></i>
              <i
                className="fa fa-times-circle remove"
                aria-hidden="true"
                onClick={() => deleteQuote(quote._id)}
              ></i>
            </div>
            <hr />
          </div>
        ))}
      </div>
    );
}
 
export default ListQuotes;