import CreateQuotes from "./CreateQuotes";
import ListQuotes from "./ListQuotes";

import './index.css';
import { useState } from "react";
import axios from "axios";

const Quotes = () => {
    const [quoteData, setQuoteData] = useState([]);

    const url = process.env.REACT_APP_API_ENDPOINT + "/api/quote";

    const getQuotes = async () => {
        try {
            const quotes = await axios.get(url);
            setQuoteData(quotes.data);
        } catch (err) {
            console.log(err);
        }
    }

    return ( 
        <div className = "Quotes">
            <CreateQuotes getQuotes={getQuotes} />
            <ListQuotes quoteData={quoteData} getQuotes={getQuotes} />
        </div>
     );
}
 
export default Quotes;