import axios from 'axios';
import React from 'react';
import './index.css';

export default class FeaturedItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookList: [],
            blogList: [],
            productList: [],
            featuredItemsList: [],
            featuredBooks: [],
            featuredBlogs: [],
            featuredProducts: [],
        };
    }

    componentDidMount() {
        this.getFeaturedItems();
        this.getBookList();
        this.getBlogList();
        this.getProductList();
    }

    getBookList = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/products?category=book')
        .then(response => {
            this.setState({bookList: response.data});
        }).catch(err => console.log(err));
    }

    getBlogList = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/blog')
        .then(response => {
            this.setState({blogList: response.data});
        }).catch(err => console.log(err));
    }

    getProductList = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/products?category=book&exclude=true')
        .then(response => {
            this.setState({productList: response.data});
        }).catch(err => console.log(err));
    }

    getFeaturedItems = () => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/featured-item')
        .then(response => {
            let featuredBooks = response.data.filter(item => item.category === 'book');
            featuredBooks = featuredBooks.sort((a, b) => {
                if (parseInt(a['item']) > parseInt(b['item'])) {
                    return 1
                };
                return -1;
            });
            let featuredBlogs = response.data.filter(item => item.category === 'blog');
            featuredBlogs = featuredBlogs.sort((a, b) => {
                if (parseInt(a['item']) > parseInt(b['item'])) {
                    return 1
                };
                return -1;
            });
            let featuredProducts = response.data.filter(item => item.category === 'product');
            featuredProducts = featuredProducts.sort((a, b) => {
                if (parseInt(a['item']) > parseInt(b['item'])) {
                    return 1
                };
                return -1;
            });

            this.setState({
                featuredItemsList: response.data,
                featuredBooks,
                featuredBlogs,
                featuredProducts
            });
        }).catch(err => console.log(err));
    }

    handleUpdate = (featuredItem) => {
        const payload = {
            productid: featuredItem.productid
        };
        axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/featured-item/' + featuredItem._id, payload)
        .then(response => {
            console.log('response: ', response);
        }).catch(err => console.log(err));
    }

    handleSelect = (value, index, type) => {
        this.setState((state) => {
            state[type][index].productid = value;
            return state;
        });
    }

    render() {
        return (
            <div className="fi-container">
                <div className="row">
                    <strong>Featured Books</strong>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 0, 'featuredBooks')}
                            className="fi-select"
                            value={
                            this.state.featuredBooks[0]
                            ? this.state.featuredBooks[0].productid
                            : ''}>
                            {
                                this.state.bookList.map((bookData) => {
                                    return (
                                        <option value={bookData._id} key={bookData._id}>{bookData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBooks[0])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 1, 'featuredBooks')}
                            className="fi-select"
                            value={
                            this.state.featuredBooks[1]
                            ? this.state.featuredBooks[1].productid
                            : ''}>
                            {
                                this.state.bookList.map((bookData) => {
                                    return (
                                        <option value={bookData._id} key={bookData._id}>{bookData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBooks[1])}
                            className="fi-button">UPDATE</button>
                    </div><div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 2, 'featuredBooks')}
                            className="fi-select"
                            value={
                            this.state.featuredBooks[2]
                            ? this.state.featuredBooks[2].productid
                            : ''}>
                            {
                                this.state.bookList.map((bookData) => {
                                    return (
                                        <option value={bookData._id} key={bookData._id}>{bookData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBooks[2])}
                            className="fi-button">UPDATE</button>
                    </div>
                </div>
                <hr className="section-divider" />
                <div className="row">
                    <strong>Featured Blogs</strong>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 0, 'featuredBlogs')}
                            className="fi-select"
                            value={
                            this.state.featuredBlogs[0]
                            ? this.state.featuredBlogs[0].productid
                            : ''}>
                            {
                                this.state.blogList.map((blogData) => {
                                    return (
                                        <option value={blogData._id} key={blogData._id}>{blogData.title}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBlogs[0])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 1, 'featuredBlogs')}
                            className="fi-select"
                            value={
                            this.state.featuredBlogs[1]
                            ? this.state.featuredBlogs[1].productid
                            : ''}>
                            {
                                this.state.blogList.map((blogData) => {
                                    return (
                                        <option value={blogData._id} key={blogData._id}>{blogData.title}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBlogs[1])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 2, 'featuredBlogs')}
                            className="fi-select"
                            value={
                            this.state.featuredBlogs[2]
                            ? this.state.featuredBlogs[2].productid
                            : ''}>
                            {
                                this.state.blogList.map((blogData) => {
                                    return (
                                        <option value={blogData._id} key={blogData._id}>{blogData.title}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBlogs[2])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 3, 'featuredBlogs')}
                            className="fi-select"
                            value={
                            this.state.featuredBlogs[3]
                            ? this.state.featuredBlogs[3].productid
                            : ''}>
                            {
                                this.state.blogList.map((blogData) => {
                                    return (
                                        <option value={blogData._id} key={blogData._id}>{blogData.title}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredBlogs[3])}
                            className="fi-button">UPDATE</button>
                    </div>
                </div>
                <hr className="section-divider" />
                <div className="row">
                    <strong>Featured Products</strong>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 0, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[0]
                            ? this.state.featuredProducts[0].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[0])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 1, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[1]
                            ? this.state.featuredProducts[1].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[1])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 2, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[2]
                            ? this.state.featuredProducts[2].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[2])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 3, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[3]
                            ? this.state.featuredProducts[3].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[3])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 4, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[4]
                            ? this.state.featuredProducts[4].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[4])}
                            className="fi-button">UPDATE</button>
                    </div>
                    <div className="col-md-10">
                        <select
                            onChange={(event) => this.handleSelect(event.target.value, 5, 'featuredProducts')}
                            className="fi-select"
                            value={
                            this.state.featuredProducts[5]
                            ? this.state.featuredProducts[5].productid
                            : ''}>
                            {
                                this.state.productList.map((productData) => {
                                    return (
                                        <option value={productData._id} key={productData._id}>{productData.productName}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <button
                            onClick={() => this.handleUpdate(this.state.featuredProducts[5])}
                            className="fi-button">UPDATE</button>
                    </div>
                </div>
            </div>
        );
    }
}