import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.css";

const SupplierDashBoard = () => {
  const [productCount, setProductCount] = useState("");
  const [orderCount, setOrderCount] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const id = useSelector((state) => state.login.user.id);
  let no = 0;

  useEffect(() => {
    products();
    orders();
  }, []);

  const products = async () => {
    const productsApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/products");
    const productData = productsApi.data;
    let count = 0;
    productData
      .filter((data) => data.id === id)
      .map((data) => {
        console.log(data.id);
        count += 1;
      });
    setProductCount(count);
  };

  const orders = async () => {
    const orderApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/transaction");
    const orderData = orderApi.data;
    let individualSupplier = [];
    let supp = [];
    orderData.forEach((data) => {
      individualSupplier.push(data.productDetails);
    });
    individualSupplier.map((data) => {
      data.forEach((d) => {
        supp.push(d);
      });
    });
    let count = 0;
    setOrderDetails(supp);
    const supplierFilter = supp.filter((data) => data.pId === id);
    supplierFilter.forEach((data) => {
      count += 1;
    });
    setOrderCount(count);
  };

  return (
    <div className="DashBoard">
      <div className="cards">
        <div className="card card-one">
          <div className="value">{productCount}</div>
          <div className="name">Products</div>
        </div>
        <div className="card card-two">
          <div className="value">{orderCount}</div>
          <div className="name">Orders</div>
        </div>
      </div>
      {/* <div className="dashboard-table">
        <h3>Recent Orders</h3>
        <table className="table">
          <tr>
            <th className="table-head">No</th>
            <th className="table-head">Order ID</th>
            <th className="table-head">Trainer Name</th>
            <th className="table-head">Invoice Amount</th>
            <th className="table-head">Purchased On</th>
          </tr>
          {orderDetails
            .filter((data) => data.pId === id)
            .slice(0, 3)
            .map((data) => (
              <tr className="table-even" key={data.pId}>
                <td className="table-body" data-label="No">
                  {(no += 1)}
                </td>
                <td className="table-body" data-label="Order ID">
                  {"CO8456" + no}
                </td>
                <td className="table-body" data-label="Trainer Name">
                  {data.customerName}
                </td>
                <td className="table-body" data-label="Invoice Amount">
                  {data.pTotal}
                </td>
                <td className="table-body" data-label="Purchased On">
                  {data.purchasedOn}
                </td>
              </tr>
            ))}
        </table>
      </div> */}
    </div>
  );
};

export default SupplierDashBoard;
