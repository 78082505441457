import { useEffect } from "react";
import axios from "axios";

import "./index.css";

const ClipsList = ({ clipData, getClipData }) => {
  const url = process.env.REACT_APP_API_ENDPOINT + "/api/clip";

  const deleteClip = async (id) => {
    try {
      await axios.delete(url + "/" + id);
      getClipData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClipData();
  }, []);

  return (
    <div className="Clips-List">
      {clipData.map((clip) => (
        <div className="clip-list-grid" key={clip._id}>
          {/* <img src='' alt="missing" /> */}
          {/* <Redirect exact to = {clip.url}>{clip.url}</Redirect> */}
          <a href={clip.url} target="_blank">
            {clip.url}
          </a>
          <div>
            {clip.title}
          </div>
          <i
            className="fa fa-times-circle"
            aria-hidden="true"
            onClick={() => deleteClip(clip._id)}
          ></i>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default ClipsList;
