import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import './index.css';

const Packages = () => {
  const [userPackage, setUserPackage] = useState([]);
  const [l1Active, setL1Active] = useState(false);
  const [l2Active, setL2Active] = useState(false);
  const [l3Active, setL3Active] = useState(false);
  // const [userType, setUserType] = useState(null);
  // const [userId, setUserId] = useState(null);
  // const userId = useSelector((state) => state.login.user.id);
  const userId = localStorage.getItem('userId');
  const [updateProduct, setUpdateProduct] = useState({
    id: "",
    count: 50,
  });
  // const [count,setCount]=useState(50)
  const [show, setShow] = useState(false);
  const levelOneTotal = 40;
  const levelTwoTotal = 100;
  const levelThreeTotal = 200;

  useEffect(() => {
    getPackage();
    
  }, []);

  const getPackage = async () => {
    let count = 0;
    let user='';
    
    try {
      const packageApi = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/package");
      const packageData = packageApi.data;
      packageData
        .filter((data) => data.userId === userId)
        .map((d) => {
          if (d.package === "level-3") {
            setUserPackage(d);
            setL1Active(true)
            setShow(true);
            count = 100;
            user=d._id
          } else if (d.package === "level-2") {
            setUserPackage(d);
            setL2Active(true)
            setShow(true);
            count = 50;
            user=d._id
          } else if (d.package === "level-1") {
            setUserPackage(d);
            setL3Active(true)
            setShow(true);
            count = 10;
            user=d._id
          }
        });
      await axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/productsUpdate", {
        id: user,
        count: count,
      });
    } catch (err) {
      console.log(err);
    }

  };

  const levelOnePayment = (token) => {


    var today = new Date();
    var currentDate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    var expiryDate =
      today.getMonth() + 2 + "/" + today.getDate() + "/" + today.getFullYear();
    const body = {
      token,
      total: levelOneTotal,
      userId: localStorage.getItem('userId'),
      currentPaidStatus:true,
      package: "level-1",
      packageAmount: "$" + levelOneTotal,
      getDate: currentDate,
      expiryDate: expiryDate,
      userType:localStorage.getItem('userType')
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return fetch(process.env.REACT_APP_API_ENDPOINT + "/api/package", {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
    .then((response) => {
      getPackage();
    }).catch((err) => console.log(err));
  };

  const levelTwoPayment = (token, charge) => {
    
    var today = new Date();
    var currentDate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    var expiryDate =
      today.getMonth() + 2 + "/" + today.getDate() + "/" + today.getFullYear();
    console.log(token);
    console.log(charge);
    const body = {
      token,
      total: levelTwoTotal,
      userId: userId,
      currentPaidStatus:true,
      package: "level-2",
      packageAmount: "$" + levelTwoTotal,
      getDate: currentDate,
      expiryDate: expiryDate,
      userType:localStorage.getItem('userType')
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return fetch(process.env.REACT_APP_API_ENDPOINT + "/api/package", {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
    .then((response) => {
      getPackage();
    }).catch((err) => console.log(err));
  };

  const levelThreePayment = (token, charge) => {
    
    console.log(charge.id);
    var today = new Date();
    var currentDate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    var expiryDate =
      today.getMonth() + 2 + "/" + today.getDate() + "/" + today.getFullYear();
    const body = {
      token,
      charge,
      total: levelThreeTotal,
      userId: userId,
      currentPaidStatus:true,
      package: "level-3",
      packageAmount: "$" + levelThreeTotal,
      getDate: currentDate,
      expiryDate: expiryDate,
      userType:localStorage.getItem('userType')
    };

    const headers = {
      "Content-Type": "application/json",
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + "/api/package", {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
    .then((response) => {
      getPackage();
    })
    .catch((err) => console.log(err));
  };

  return (
    <div className="Packages">
      {/* {userPackage.map((data) => ( */}
      <div className="plan-validity">
        <h4>
          Current Plan: <span>{show ? userPackage.package : "----"}</span>
        </h4>
        <h4>
          Validity:{" "}
          <span>Expires on {show ? userPackage.expiryDate : "----"}</span>
        </h4>
      </div>
      {/* ))} */}
      <div className="package-cards">
        <div className="package-card card-green">
          <div className="level level-1">Level 1</div>
          <div className="cost-per-month">
            <h4>
              <span>$40</span> / Mo
            </h4>
          </div>
          <div className="commission">
            <ul>
              <li>10% Commission</li>
              <li> {localStorage.getItem('userType') === 'coach' ? 'Upto 3 Clients':  localStorage.getItem('userType')==='seller'?'Upto 10 Products':' ' } </li>
            </ul>
          </div>
          <div className="green">
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIP_KEY}
              token={levelOnePayment}
              shippingAddress={true}
              billingAddress={true}
              amount={levelOneTotal * 100}
              name="Product Buy"
            >
              <button className="buy_btn">BUY NOW</button>
            </StripeCheckout>
          </div>
        </div>

        <div className="package-card card-rose">
          <div className="level level-2">Level 2</div>
          <div className="cost-per-month">
            <h4>
              <span>$100</span> / Mo
            </h4>
          </div>
          <div className="commission">
            <ul>
              <li>5% Commission</li>
              <li>{localStorage.getItem('userType') === 'coach' ? 'Upto 10 Clients':  localStorage.getItem('userType')==='seller'?'Upto 50 Products':' ' } </li>
            </ul>
          </div>
          <div className="rose">
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIP_KEY}
              token={levelTwoPayment}
              shippingAddress={true}
              billingAddress={true}
              amount={levelTwoTotal * 100}
              name="Product Buy"
            >
              <button className="buy_btn" >BUY NOW</button>
            </StripeCheckout>
          </div>
        </div>

        <div className="package-card card-blue">
          <div className="level level-3">Level 3</div>
          <div className="cost-per-month">
            <h4>
              <span>$200</span> / Mo
            </h4>
          </div>
          <div className="commission">
            <ul>
              <li>1% Commission</li>
              <li>{localStorage.getItem('userType') === 'coach' ? 'Unlimited Clients':  localStorage.getItem('userType')==='seller'?'Unlimited Products':' ' }</li>
            </ul>
          </div>
          <div className="bluu">
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIP_KEY}
              token={levelThreePayment}
              shippingAddress={true}
              billingAddress={true}
              amount={levelThreeTotal * 100}
              name="Product Buy"
            >
              <button className="buy_btn" >BUY NOW</button>
            </StripeCheckout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
