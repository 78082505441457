import * as actionTypes from "./loginTypes";

export const getUser = (email, password, userType, id, name) => {
  return {
    type: actionTypes.GET_USER,
    payload: {
      email,
      password,
      userType, 
      id,
      name,
    },
  };
};

export const removeUser = () => {
  return {
    type: actionTypes.REMOVE_USER,
  };
};

export const getForgetUser = (email, otp, id) => {
  return {
    type: actionTypes.GET_FORGET_USER,
    payload: {
      email,
      otp,
      id,
    },
  };
};

export const getForgetUserId = (id) => {
  return {
    type: actionTypes.GET_FORGET_USER_ID,
    payload: {
      id,
    },
  };
};
