import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { removeUser } from "../Redux/reduxLogin/loginActions";
import { useHistory } from "react-router";

import WhiteLogo from "../../Images/cornerco-logo.png";

import "./index.css";
import axios from "axios";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = localStorage.getItem('token');

  const logout = () => {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/users/logout', {}, {
      headers: {
        'x-auth': token
      }
    });
  }

  const signOut = async () => {
    
   let res= window.confirm("Are you sure to logout?")
  //  console.log(res);
   if(res){
    await logout();
    dispatch(removeUser());
    localStorage.clear();
    window.location.href = window.location.origin + '/';
   }
  };

  return (
    <div className="Header">
      <div className="white-logo">
        <img  src={WhiteLogo} className="header-logo" />
      </div>
      <div className="icon-log">
        
          <i
            className="fa fa-power-off i-logout"
            aria-hidden="true"
            onClick={() => signOut()}
          ></i>
        
      </div>
    </div>
  );
};

export default Header;
