import { NavLink } from "react-router-dom";

import logo from "../../Images/logo.png";

import "./index.css";

const Navbar = () => {
  const userEmail = localStorage.getItem('email');
  let userName = localStorage.getItem('userName');
  userName = userName === undefined || userName === 'undefined' ? '' : userName;
  return (
    <div className="Navbar">
      <div className="side-navbar-admin">
        <img className="navbar-logo" src={logo} alt="logo-missing" />
        <div className="userinfo-container">
          <span>{userName || ''}</span>
          <span>{userEmail || ''}</span>
        </div>
        <div className="navlinks-container">
          <div>
            <NavLink
              exact 
              to="/dashboard"
              activeClassName="active"
              className="nav-link"
            >
              Dashboard
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/list-suppliers"
              activeClassName="active"
              className="nav-link"
            >
              Suppliers
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/payouts"
              activeClassName="active"
              className="nav-link"
            >
              Payouts
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/ads"
              activeClassName="active"
              className="nav-link"
            >
              ADs
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/reported-profile"
              activeClassName="active"
              className="nav-link"
            >
              Reported Profiles
            </NavLink>
          </div>
          {/* <div>
            <NavLink
              exact
              to="/change-password"
              activeClassName="active"
              className="nav-link"
            >
              Change Password
            </NavLink>
          </div> */}
          <div>
            <NavLink
              exact
              to="/clips"
              activeClassName="active"
              className="nav-link"
            >
              Clips
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/quotes"
              activeClassName="active"
              className="nav-link"
            >
              Quotes
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/featured-items"
              activeClassName="active"
              className="nav-link"
            >
              Featured Items
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/featured-quote"
              activeClassName="active"
              className="nav-link"
            >
              Featured Quote
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/coach-requests"
              activeClassName="active"
              className="nav-link"
            >
              Coach Requests
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
