import React from "react";
import axios from "axios";
import "./index.css";
import Select from "react-select";
import { nanoid } from "nanoid";
import "bootstrap/dist/css/bootstrap.min.css";

import S3FileUpload from "react-s3";
import { Spinner } from "react-bootstrap";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export default class CreateBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [
        { label: "Relationships", value: 1 },
        { label: "Depression & anxiety", value: 2 },
        { label: "Career & workplace", value: 3 },
        { label: "Investment & business", value: 4 },
        { label: "Lockdown", value: 5 },
        { label: "Spiritual beliefs", value: 6 },
        { label: "Health & Fitness", value: 7 },
        { label: "Personal development", value: 8 },
        { label: "Sexual health", value: 9 },
        { label: "Parenting", value: 10 },
        { label: "Abuse", value: 11 },
        { label: "Goals", value: 12 },
        { label: "Studying", value: 13 },
        { label: "Self-harm & suicide", value: 14 },
      ],
      selectedOptions: [],
      blogImageUrl: null,
      selectedLabels: "",
      createButtonEnabler: false,
      loading: false,
      formFields: {
        blogImage: null,
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {}
  handleTagsChange = (e) => {
    this.setState({ selectedOptions: e });

    this.setState({
      selectedLabels: e
        .map((f) => {
          return f.label;
        })
        .join(),
    });
  };

  triggerImageButton = () => {
    this.fileButton.click();
  };

  uploadBlogImage = (event) => {
    const fileList = event.target.files;
    const imageFile = fileList[0];
    let blob = imageFile.slice(0, imageFile.size, "image/png");

    // const imageName = imageFile.name.slice(0, imageFile.name.lastIndexOf('.'))
    //   + '_' + new Date().getTime() + '.'
    //   + imageFile.name.slice(imageFile.name.lastIndexOf('.') + 1, imageFile.name.length);
    // imageFile.name = imageName;
    if (imageFile) {
      this.setState(
        (state) => {
          state.formFields.blogImage = new File([blob], "blogPost" + nanoid(), {
            type: "image/png",
          });
          return state;
        },
        () => {
          this.checkAllFields();
        }
      );
    }
  };

  checkAllFields = () => {
    let allFieldsEntered = true;
    for (let prop in this.state.formFields) {
      if (!this.state.formFields[prop]) {
        allFieldsEntered = false;
      }
    }

    if (allFieldsEntered) {
      this.setState({ createButtonEnabler: true });
    } else {
      this.setState({ createButtonEnabler: false });
    }
  };

  handleChange = (value, type) => {
    this.setState(
      (state) => {
        state.formFields[type] = value;
        return state;
      },
      () => this.checkAllFields()
    );
  };

  createBlog = () => {
    this.setState({ loading: true }, () => {
      S3FileUpload.uploadFile(this.state.formFields.blogImage, config)
        .then((data) => {
          const blogImageUrl = data.key.slice(
            data.key.lastIndexOf("/") + 1,
            data.key.length
          );
          const payload = {
            title: this.state.formFields.title,
            description: this.state.formFields.description,
            blogImageUrl: blogImageUrl,
            category: this.state.selectedLabels,
            coachId: localStorage.getItem("coachId"),
          };
          axios
            .post(process.env.REACT_APP_API_ENDPOINT + "/api/blog", payload)
            .then((res) => {
              if (res) {
                this.props.history.push("/list-blogs");
              }
              this.setState({ loading: false });
            })
            .catch((err) => this.setState({ loading: false }));
        })
        .catch((S3err) => this.setState({ loading: false }));
    });
  };

  render() {
    return (
      <div className="create-blog-container">
        <div className="row form-container">
          <div className="col-12">
            <h5 className="create-blog-title">Create Blog</h5>
          </div>
          <div className="col-md-6">
            <label className="label-container">Title</label>
            <input
              onChange={(event) =>
                this.handleChange(event.target.value, "title")
              }
              value={this.state.formFields.title}
              className="theme-red"
              type="text"
            />
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <label className="label-container">Description</label>
            <textarea
              onChange={(event) =>
                this.handleChange(event.target.value, "description")
              }
              value={this.state.formFields.description}
              className="theme-red desc-textarea"
              type="text"
            />
          </div>
          <div className="col-md-6" style={{ position: "relative" }}>
            <label className="label-container">Blog Image</label>
            <input
              ref={(ref) => (this.fileButton = ref)}
              type="file"
              className="_formImg_"
              accept="image/*"
              onChange={this.uploadBlogImage}
            />
            <span
              onClick={() => this.triggerImageButton()}
              className="image-preview-container"
            >
              {this.state.formFields.blogImage && (
                <img
                  className="blog-image-preview"
                  src={URL.createObjectURL(this.state.formFields.blogImage)}
                  alt="image2"
                />
              )}
            </span>
          </div>
          <div className="col-md-6 mb-3">
            <Select
              isMulti
              options={this.state.tags}
              value={this.state.selectedOptions}
              onChange={(e) => this.handleTagsChange(e)}
            />
          </div>
          <div className="col-md-12">
            <button
              onClick={this.createBlog}
              className="blog-button"
              disabled={!this.state.createButtonEnabler}
            >
              {this.state.loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Create Blog"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
