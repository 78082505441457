import React from 'react';
import axios from "axios";
import "./index.css";
import { Modal, Table } from 'react-bootstrap';
import * as moment from 'moment';
import Button from '@restart/ui/esm/Button';

export default class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      viewer: false,
      modalInfo: {
        productId: '',
        productName: '',
        description: '',
        availableQuantity: '',
        deliveryOption: '',
        category: '',
        discountAmount: '',
        mrp: '',
        promocode: '',
        salePrice: '',
        specification: '',
        supplierName: '',
        createdAt: ''
      }
    }
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    const sellerId = localStorage.getItem('sellerId');
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/products?userId=' + sellerId)
    .then(response => {
      this.setState({productList: response.data});
    }).catch(err => console.log(err));
  }

  handleClose = (boolVal = false, value) => {
    if (boolVal) {
      this.setState((state) => {
        state.modalInfo.productId = value._id || '--';
        state.modalInfo.productName = value.productName || '--';
        state.modalInfo.description = value.description || '--';
        state.modalInfo.availableQuantity = value.availableQuantity || '--';
        state.modalInfo.deliveryOption = value.deliveryOption || '--';
        state.modalInfo.category = value.category || '--';
        state.modalInfo.discountAmount = typeof value.discountAmount === 'string' ? value.discountAmount : '--';
        state.modalInfo.mrp = value.mrp || '--';
        state.modalInfo.promocode = value.promocode || '--';
        state.modalInfo.salePrice = value.salePrice || '--';
        state.modalInfo.specification = value.specification || '--';
        state.modalInfo.supplierName = value.supplierName || '--';
        state.modalInfo.createdAt = value.createdAt || '--';
        state.viewer = boolVal;
        return state;
      });
    } else {
      this.setState({viewer: boolVal});
    }
  }

  render() {
    return (
      <div className="productlist-container">
        <div className="row">
          <div className="col-md-6 product-heading-nav">
            My Products
          </div>
          <div className="col-md-6 product-list-nav">
            <button
              onClick={() => this.props.history.push('/products')}
              className="blog-button">ADD PRODUCT</button>
          </div>
        </div>
        <div className="row">
          <Table className="product-table" hover>
            <thead style={{height: '45px', fontSize: '15px'}}>
              <tr>
                <th>No</th>
                <th style={{width: '15vw'}}>Product ID</th>
                <th style={{width: '25vw'}}>Product Name</th>
                <th style={{whiteSpace: 'nowrap'}}>Actual Price</th>
                <th style={{whiteSpace: 'nowrap'}}>Sale Price</th>
                <th style={{width: '22vw'}}>Added On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.productList.map((productData, index) => {
                  return (
                    <tr key={productData._id}>
                      <td>{index+1}</td>
                      <td>{productData._id}</td>
                      <td>{productData.productName}</td>
                      <td>{productData.mrp}</td>
                      <td>{productData.salePrice}</td>
                      <td style={{whiteSpace: 'nowrap'}}>{productData.createdAt ? moment(new Date(productData.createdAt)).format('Do-MMM h:mmA') : ''}</td>
                      <td>
                        <i
                          onClick={() => this.handleClose(true, productData)}
                          className="fa fa-eye fa-custom-view"
                          aria-hidden="true"></i>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
        <Modal
          size="xl"
          show={this.state.viewer}
          onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Product Details</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            maxHeight: 450,
            padding: '20px',
            overflowY: 'auto'
          }}>
            <div className="row info-row-lg">
              <div className="col-md-2">Product ID</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.productId}</div>
              <div className="col-md-2">Product Name</div>
              <div className="col-md-4">{this.state.modalInfo.productName}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Available Quantity</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.availableQuantity}</div>
              <div className="col-md-2">Description</div>
              <div className="col-md-4">{this.state.modalInfo.description}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Category</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.category}</div>
              <div className="col-md-2">Delivery Options</div>
              <div className="col-md-4">{this.state.modalInfo.deliveryOption}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Discount Amount</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.discountAmount}</div>
              <div className="col-md-2">Actual Price</div>
              <div className="col-md-4">{this.state.modalInfo.mrp}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Sale Price</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.salePrice}</div>
              <div className="col-md-2">Specification</div>
              <div className="col-md-4">{this.state.modalInfo.specification}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Promo Code</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.promocode}</div>
              <div className="col-md-2">Supplier Name</div>
              <div className="col-md-4">{this.state.modalInfo.supplierName}</div>
            </div>
            <div className="row info-row-lg">
              <div className="col-md-2">Created At</div>
              <div className="col-md-4 right-line">{this.state.modalInfo.createdAt ? moment(new Date(this.state.modalInfo.createdAt)).format('D/M/Y, h:mm:ss a') : ''}</div>
              <div className="col-md-2"></div>
              <div className="col-md-4"></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
