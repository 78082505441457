import * as actionTypes from "./loginTypes";

const INTIAL_STATE = {
  user: [],
  forget: [],
  forgetUserId: [],
};

const loginReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case actionTypes.REMOVE_USER:
      return {
        ...state,
        user: [],
      };

    case actionTypes.GET_FORGET_USER:
      return {
        ...state,
        forget: action.payload,
      };
      
    case actionTypes.GET_FORGET_USER_ID:
      return {
        ...state,
        forgetUserId: action.payload,
      };

    default:
      return state;
  }
};

export default loginReducer;
