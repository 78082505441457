import { useEffect, useState } from "react";
// import { useState } from "react/cjs/react.development";
import axios from "axios";

import "./index.css";

const Ads = () => {
  const [bannerAd, setBannerAd] = useState("");
  const [featureAd, setFeatureAd] = useState("");
  const [resourcesAd, setResourcesAd] = useState("");
  const [adApiData, setAdApiData] = useState([]);
  const [homeImg, setHomeImg] = useState("");
  const bannerId = "616d46d9972cbf49b3a4cee1";
  const featureId = "616d5dd5797037727ca808e3";
  const resourcesId = "616d5def797037727ca808e5";
  const homeId = "61891a770f042f38b087984b";
  const url = process.env.REACT_APP_API_ENDPOINT + "/api/ad";

  useEffect(() => {
    fetchAdAPI();
  }, []);

  const bannerChange = (e) => {
    console.log(e.target.files);
    encodeBannerImageFileAsURL(e.target.files);
  };
  const featureChange = (e) => {
    encodeFeatureImageFileAsURL(e.target.files);
  };
  const resourcesChange = (e) => {
    encodeResourcesImageFileAsURL(e.target.files);
  };
  const homeChange = (e) => {
    encodeHomeImageFileAsURL(e.target.files);
  };
  const fetchAdAPI = async () => {
    try {
      const ad = await axios.get(url);
      setAdApiData(ad.data);
      console.log("API Fetched");
    } catch (err) {
      console.log(err);
      console.log("unable to fetch");
    }
  };

  const encodeBannerImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setBannerAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeFeatureImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setFeatureAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeResourcesImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setResourcesAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeHomeImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setHomeImg(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const updateBannerImage = async (e) => {
    e.preventDefault();
    const adBanner = { adType: "productspage_saleAD", adImage: bannerAd };
    await axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/ad/${bannerId}`, adBanner)
      .then((response) => console.log(response));
  };
  const updateFeatureImage = async (e) => {
    e.preventDefault();
    const adFeature = { adType: "featureProductAd", adImage: featureAd };
    await axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${featureId}`,
        adFeature
      )
      .then((response) => console.log(response));
  };
  const updateResourcesImage = async (e) => {
    e.preventDefault();
    const adResoures = { adType: "topResourcesAd", adImage: resourcesAd };
    await axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${resourcesId}`,
        adResoures
      )
      .then((response) => console.log(response));
  };
  const updateHomeImage = async (e) => {
    e.preventDefault();
    const HomeResoures = { adType: "HomeAD", adImage: homeImg };
    await axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${homeId}`,
        HomeResoures
      )
      .then((response) => console.log(response));
  };

  return (
    <div className="Ads">
      <div>
        <h5>Sale banner in Products page (size 500x200)</h5>
        <div className="ad-grid">
          {adApiData
            .filter((data) => data.adType === "productspage_saleAD")
            .map((ad) => (
              <div key={ad._id}>
                <img
                  src={process.env.REACT_APP_BUCKET_BASE_URL + ad.adImage}
                  alt="ad-missing"
                  className="banner-ad-image"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Ads;
