import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import "./index.css";
import S3FileUpload from "react-s3";
import { Spinner } from "react-bootstrap";
import S3 from 'react-aws-s3'

const EditProfile = (props) => {
  let { id } = useParams("");
  let {profileNum} = useParams("");
  const userId = localStorage.getItem('userId');
  const [title, setTitle] = useState("");
  const [value, setValue] = useState();
  const [specification, setSpecification] = useState("");
  const [details, setDetails] = useState("");
  const [profileImageURL, setProfileImageURL] = useState("");
  const[file,setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setupLoading] = useState(false);
  const fileInput=useRef()

  const history = useHistory();

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/blog";

  

  useEffect(() => {
    
    getBlogById();
  }, [url]);

  const getBlogById = async () => {
    try {
      const profile = await axios.get(
        process.env.REACT_APP_API_ENDPOINT + "/api/profile/" + id
      );
      setTitle(profile.data.title);
      setValue(profile.data.value);
      setSpecification(profile.data.specification);
      setDetails(profile.data.details);
    } catch (err) {
      console.log(err);
      console.log(id);
    }
  };

  // const titleChange = (e) => {
  //   setTitle(e.target.value);
  // };
  // const valueChange = (e) => {
  //   setValue(e.target.value);
  // }; 
  // const specificationChange = (e) => {
  //   setSpecification(e.target.value);
  // };
  // const deatailsChange = (e) => {
  //   setDetails(e.target.value);
  // };

  // const handleSubmit = async () => {
  //   await handleClick();
  //   await profileUpdate();
  // }

 const handleClick = (e) => {
  setupLoading(true)
   e.preventDefault();
   let file = fileInput.current.files[0];
   
   let newFileName =  userId + "-" + profileNum ;
   setProfileImageURL(newFileName)

   const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const ReactS3Client = new S3(config);

  ReactS3Client.uploadFile(file,newFileName).then(data=> {
    console.log(data);
    if(data.status === 204) {
      console.log('success')
      setupLoading(false)
    } else {
      console.log('fail')
    }
  })
 }

  const profileUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      title: title,
      value: value,
      specification: specification,
      details: details,
      profileImage:profileImageURL
    
    };
    await axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/api/updateprofile/" + id , data)
      .then((res) => {
        setLoading(false);
        history.push("/coach-profile");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="EditProfile">
      <h3>Edit Profile</h3>
      <form onSubmit={profileUpdate}>
        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Profile Title</label>
            </div>
            <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} />
          </div>
          <div className="form-width">
            <div>
              <label>Value</label>
            </div>
            <input type="number" value={value} onChange={(e)=>setValue(e.target.value)} />
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Specification</label>
            </div>
            <textarea
              type="text"
              value={specification}
              onChange={(e)=>setSpecification(e.target.value)}
            />
          </div>
          <div className="form-width">
            <div>
              <label>Details</label>
            </div>
            <textarea type="text" value={details} onChange={(e)=>setDetails(e.target.value)} />
          </div>
          <div className="form-width" style={{display:'flex',alignContent:'center'}}>
           
      <label className="imglabel" htmlFor="fileInput">
      
      <span className='writeIcon'>Add Image{file && ( 
      <img className="imgUpload" src={URL.createObjectURL(file)} alt="" />
      )}
            </span>
          </label>
         
            <input type='file' id="fileInput" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} ref={fileInput} />
            <div  className="uploadInfo_container">
            <button className="imgUpload_btn" onClick={handleClick} disabled={uploading}>
            {
              uploading
              ? <Spinner 
                  animation="border" role="status">
                <span className="visually-hidden">Uploading...</span>
              </Spinner>
              : 'Upload'
            }</button>
            <span className="upload_info">Upload Image before Saving the details</span>
            </div>
           
          </div>
        </div>
        <div className="center-button">
          <button
          className="save_btn"
            type="submit"
            disabled={loading}>
            {
              loading
              ? <Spinner 
                  animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              : 'SAVE'
            }
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
