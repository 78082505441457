import { useState, useEffect } from "react";
import axios from "axios";

import "./index.css";
import { Table } from "react-bootstrap";
import moment from "moment";

const ReportedProfile = () => {
  const [reportData, setReportData] = useState([]);
  // const [id, setId] = useState();
  const [block, setBlock] = useState(Boolean);
  const [status, setStatus] = useState(Number);

  const [getStatus, setGetStatus] = useState();

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/report";

  const getReportedData = async () => {
    try {
      const reports = await axios.get(url);
      setReportData(reports.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(async () => {
    getReportedData();
  }, []);


  const statusChange = (e) => {
    console.log(e.target.value);
    setGetStatus(e.target.value);
    console.log(getStatus);
  };

  const clickBlock = async(id, boolVal) => {
    console.log(id);
    const data = { block: !boolVal };
    axios
    .put(process.env.REACT_APP_API_ENDPOINT + "/api/report/" + id, data)
    .then((res) => {
      getReportedData();
    })
    .catch((err) => {
      console.log('err: ', err);
    });
  }

  const clickIgnore = async (id) => {
    console.log(id);
    await fetch(url + "/" + id)
      .then((respone) => respone.json())
      .then((data) => setStatus(data.status))
      .then(() => {
        const data = { status: status + 1 };
        axios
          .put(process.env.REACT_APP_API_ENDPOINT + "/api/report/" + id, data)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="cr-container">
      <div className="row" style={{marginBottom: 15}}>
          <strong>Reported Profiles</strong>
      </div>
      <div className="row">
          <Table className="product-table" hover>
              <thead className="thead-cr" style={{height: '40px', fontSize: '14px'}}>
              <tr className="tr-cr-head">
                  <th>No</th>
                  <th style={{width: '20vw'}}>Reported User</th>
                  <th style={{width: '20vw'}}>Reported By</th>
                  <th style={{width: '15vw'}}>Reported Reason</th>
                  <th style={{width: '15vw'}}>Reported For</th>
                  <th style={{width: '13vw'}}>Reported On</th>
                  {/* <th>Status</th> */}
                  <th
                    // colSpan="2"
                    style={{width: '25vw'}}>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                  reportData.map((report, index) => {
                    return (
                      <tr key={report._id} className="tr-cr">
                          <td>{index+1}</td>
                          <td>{report.reportedUser ? report.reportedUser.userName : '--'}</td>
                          <td>{report.reportedBy ? report.reportedBy.userName : '--'}</td>
                          <td>{report.reportedReason}</td>
                          <td>{report.status+" Times"}</td>
                          <td style={{whiteSpace: 'nowrap'}}>{report.reportedOn ? moment(new Date(report.reportedOn)).format('Do-MMM-Y h:mmA') : ''}</td>
                          {/* <td>{report.status}</td> */}
                          <td
                            onClick={() => clickBlock(report._id, report.block)}
                            className="cr-yes">
                              {report.block ? 'Unblock' : 'Block'}
                          </td>
                          {/* <td
                            onClick={() => clickIgnore(report._id)}
                            className="cr-no">Ignore</td> */}
                      </tr>
                    )
                  })
              }
              </tbody>
          </Table>
      </div>
  </div>
  );
};

export default ReportedProfile;
