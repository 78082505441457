import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {useHistory} from 'react-router';
import "./index.css";
import { nanoid } from "nanoid";
import S3FileUpload from "react-s3";
import { useLocation } from "react-router-dom";

const BlogEdit = () => {
  let { id } = useParams("");

  const [blogData, setBlog] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const userId = localStorage.getItem('userId')
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  // const url = "http://3.144.43.94:3001/api/blog";

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  useEffect(() => {
    getBlogById();
  }, []);

  const getBlogById = async () => {
    try {
      
      
      const blog = await axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/getblog?path=" + path);
      console.log(blog.data[0])
      setBlog(blog.data[0]);
      setBlogTitle(blog.data[0].title);
      console.log(blogData)
      setBlogDescription(blog.data[0].description);
      setBlogImage(blog.data[0].blogImageUrl);
    } catch (err) {
      console.log(err);
      console.log(id);
    }
  };

  // const blogChange = (e) => {
  //   setBlog(e.target.value);
  //   console.log(e.target.value);
  // };

  const blogUpdate = async (e) => {
    // e.preventDefault();
    // S3FileUpload.uploadFile(this.state.formFields.blogImage, config)
    //     .then((data) => {
    //       const blogImageUrl = data.key.slice(
    //         data.key.lastIndexOf("/") + 1,
    //         data.key.length
    //       );
    const data = {title: blogTitle,description:blogDescription}
    axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/api/blog/" + userId, data)
      .then((res) => console.log(res), history.push("/list-blogs"))
      .catch((err) => console.log(err));
  };
  

  // const uploadBlogImage = (event) => {
  //   const fileList = event.target.files;
  //   const imageFile = fileList[0];
  //   let blob = imageFile.slice(0, imageFile.size, "image/png");

  //   const imageName = imageFile.name.slice(0, imageFile.name.lastIndexOf('.'))
  //     + '_' + new Date().getTime() + '.'
  //     + imageFile.name.slice(imageFile.name.lastIndexOf('.') + 1, imageFile.name.length);
  //   imageFile.name = imageName;
  //   if (imageFile) {
  //     this.setState(
  //       (state) => {
  //         state.formFields.blogImage = new File([blob], "blogPost" + nanoid(), {
  //           type: "image/png",
  //         });
  //         return state;
  //       },
  //       () => {
  //         this.checkAllFields();
  //       }
  //     );
  //   }
  // };

  return (
    <div className="BlogEdit">
      <h2>Edit Blog</h2>
      <form onSubmit={blogUpdate}>
        <div className="form-quote">
          <div className="form-quote-width">
            <div>
              <label>Blog</label>
            </div>
            {/* <img
                        style={{ height: "50px" }}
                        src={
                          blogImage &&
                          blogImage.includes("http")
                            ? blogImage
                            : process.env.REACT_APP_BUCKET_BASE_URL +
                            blogImage
                        }
                        alt={blogData.title}
                      /> */}
            <input
              type="text"
              value={blogTitle}
              onChange={(e)=>setBlogTitle(e.target.value)}
            />
            <textarea
              type="text"
              value={blogDescription}
              onChange={(e)=>setBlogDescription(e.target.value)}
            />
            {/* <div className="col-md-6" style={{ position: "relative" }}>
            <label className="label-container">Blog Image</label>
            <input
              ref={(ref) => (this.fileButton = ref)}
              type="file"
              className="_formImg_"
              accept="image/*"
              onChange={this.uploadBlogImage}
            />
            <span
              onClick={() => this.triggerImageButton()}
              className="image-preview-container"
            >
              {this.state.formFields.blogImage && (
                <img
                  className="blog-image-preview"
                  src={URL.createObjectURL(this.state.formFields.blogImage)}
                  alt="image2"
                />
              )}
            </span>
          </div> */}
          </div>
        </div>
        <button type="submit" className="product-button">
          Update
        </button>
      </form>
    </div>
  );
};

export default BlogEdit;
