import React from "react";
import axios from "axios";
import "./index.css";
import { Modal, Table } from "react-bootstrap";
import * as moment from "moment";
import {Link} from 'react-router-dom'
import Button from "@restart/ui/esm/Button";

export default class ListBlogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogList: [],
      viewer: false,
      modalInfo: {
        coachId: "",
        blogId: "",
        title: "",
        description: "",
        image: "",
        createdAt: "",
      },
    };
  }

  componentDidMount() {
    this.getBlogs();
  }

  getBlogs = () => {
    const coachId = localStorage.getItem("coachId");
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/blog?userId=" + coachId)
      .then((response) => {
        this.setState({ blogList: response.data });
      })
      .catch((err) => console.log(err));
  };

  handleClose = (boolVal = false, value) => {
    if (boolVal) {
      this.setState((state) => {
        state.modalInfo.coachId = value.coachId;
        state.modalInfo.blogId = value._id;
        state.modalInfo.title = value.title;
        state.modalInfo.description = value.description;
        state.modalInfo.image = value.blogImageUrl;
        state.modalInfo.createdAt = value.created_at;
        state.viewer = boolVal;
        return state;
      });
    } else {
      this.setState({ viewer: boolVal });
    }
  };

  render() {
    return (
      <div className="bloglist-container">
        <div className="row">
          <div className="col-md-6 blog-heading-nav">My Blogs</div>
          <div className="col-md-6 blog-list-nav">
            <button
              onClick={() => this.props.history.push("/create-blog")}
              className="blog-button"
            >
              Create New Blog
            </button>
          </div>
        </div>
        <div className="row">
          <Table className="blog-table" hover>
            <thead>
              <tr>
                <th>No</th>
                <th style={{ width: "40vw" }}>Title</th>
                <th style={{ width: "20vw" }}>No of views</th>
                <th style={{ width: "20vw" }}>Image</th>
                <th style={{ width: "20vw" }}>Created At</th>
                <th>Action</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {this.state.blogList.map((blogData, index) => {
                return (
                  <tr key={blogData._id}>
                    <td>{index + 1}</td>
                    <td>{blogData.title}</td>
                    <td>{blogData.views}</td>
                    <td>
                      <img
                        style={{ height: "50px" }}
                        src={
                          blogData.blogImageUrl &&
                          blogData.blogImageUrl.includes("http")
                            ? blogData.blogImageUrl
                            : process.env.REACT_APP_BUCKET_BASE_URL +
                              blogData.blogImageUrl
                        }
                        alt={blogData.title}
                      />
                    </td>
                    <td>
                      {blogData.created_at
                        ? moment(new Date(blogData.created_at)).format(
                            "D/M/Y, h:mm:ss a"
                          )
                        : ""}
                    </td>
                    <td>
                      <i
                        onClick={() => this.handleClose(true, blogData)}
                        className="fa fa-eye fa-custom-view"
                        aria-hidden="true"
                      ></i>
                    </td>
                    <Link to={`/blog-edit/${blogData._id}`}>
                    <td>
                    <i class="fa-solid fa-pen-to-square"></i>
                    </td>
                    </Link>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Modal size="lg" show={this.state.viewer} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Blog Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: 450,
              overflowY: "auto",
            }}
          >
            <div className="row info-row">
              <div className="col-md-6">Title</div>
              <div className="col-md-6">{this.state.modalInfo.title}</div>
            </div>
            <div className="row info-row">
              <div className="col-md-6">Description</div>
              <div className="col-md-6">{this.state.modalInfo.description}</div>
            </div>
            <div
              className="row"
              style={{
                margin: "25px 0px",
                marginLeft: "14px",
              }}
            >
              <div className="col-md-6" style={{ padding: "0px" }}>
                Image
              </div>
              <div className="col-md-6">
                <img
                  style={{ height: "100px" }}
                  src={
                    this.state.modalInfo &&
                    this.state.modalInfo.image &&
                    this.state.modalInfo.image.includes("http")
                      ? this.state.modalInfo.image
                      : process.env.REACT_APP_BUCKET_BASE_URL +
                        this.state.modalInfo.image
                  }
                  alt={this.state.modalInfo.title}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
