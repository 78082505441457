import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./index.css";

const ProductEdit = () => {
  let { id } = useParams("");

  const [category, setCategory] = useState("");
  const [productName, setProductName] = useState();
  const [mrp, setMrp] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [specification, setSpecification] = useState("");
  const [description, setDescription] = useState("");
  const [imageOne, setImageOne] = useState("");
  // const[imageTwo, setImageTwo] = useState('');
  // const[imageThree, setImageThree] = useState('');
  const [deliveryOption, setDeliveryOption] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");

  const url = "http://3.144.43.94:3001/api/products";

  useEffect(() => {
    getProductById();
  }, [url]);

  const getProductById = async () => {
    try {
      const product = await axios.get(
        "http://3.144.43.94:3001/api/products/" + id
      );
      console.log(product);
      setCategory(product.data.category);
      setProductName(product.data.productName);
      setMrp(product.data.mrp);
      setSalePrice(product.data.salePrice);
      // setImageOne('http://3.144.43.94:3001/'+product.data.avatar);
      setSpecification(product.data.specification);
      setDescription(product.data.description);
      // =========================================================================================
      // -------------------------------------- CHANGES MADE -------------------------------------
      // =========================================================================================
      setDeliveryOption(product.data.deliveryOption);
      setAvailableQuantity(product.data.availableQuantity);
    } catch (err) {
      console.log(err);
      console.log(id);
    }
  };

  const categoryChange = (e) => {
    setCategory(e.target.value);
    console.log(e.target.value);
  };
  const productNameChange = (e) => {
    setProductName(e.target.value);
  };
  const mrpChange = (e) => {
    setMrp(e.target.value);
  };
  const salePriceChange = (e) => {
    setSalePrice(e.target.value);
  };
  const specificationChange = (e) => {
    setSpecification(e.target.value);
  };
  const descriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const imageOneChange = (e) => {
    // =========================================================================================
    // -------------------------------------- CHANGES MADE -------------------------------------
    // =========================================================================================
    setImageOne(e.target.files);
  };
  // const imageTwoChange = (e) => {
  //     setImageTwo(e.target.files[0]);
  // }
  // const imageThreeChange = (e) => {
  //     setImageThree(e.target.files[0]);
  // }
  const deliveryOptionChange = (e) => {
    setDeliveryOption(e.target.value);
  };
  const availableQuantityChange = (e) => {
    setAvailableQuantity(e.target.value);
  };

  const productUpdate = async (e) => {
    e.preventDefault();
    console.log(imageOne);
    const data = new FormData();
    // =========================================================================================
    // -------------------------------------- CHANGES MADE -------------------------------------
    // =========================================================================================
    if (imageOne != "" && imageOne.length > 0) {
      for (let i = 0; i < imageOne.length; i++) {
        data.append("avatar", imageOne[i]);
      }
    }
    data.append("category", category);
    data.append("productName", productName);
    data.append("mrp", mrp);
    data.append("salePrice", salePrice);
    data.append("specification", specification);
    data.append("description", description);
    // data.append("avatar", imageOne);
    // data.append('firstImage', imageTwo);
    // data.append('secondImage', imageThree);
    data.append("deliveryOption", deliveryOption);
    data.append("availableQuantity", availableQuantity);

    axios
      .put("http://3.144.43.94:3001/api/products/" + id, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <div className="Products">
      <h2>Add Product</h2>
      <form onSubmit={productUpdate} encType="multipart/form-data">
        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Category</label>
            </div>
            <select name="category" value={category} onChange={categoryChange}>
              <option value="vitamin">Vitamins</option>
              <option value="book">Book</option>
              <option value="oil">Essential Oils</option>
            </select>
          </div>
          <div className="form-width">
            <div>
              <label>Product Name</label>
            </div>
            <input type="text" value={productName} onChange={productNameChange} />
          </div>
        </div>

        <div className="form-width-3">
          <div className="form-width">
            <div>
              <label>MRP</label>
            </div>
            <input type="number" value={mrp} onChange={mrpChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Sale Price</label>
            </div>
            <input type="number" value={salePrice} onChange={salePriceChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Specification</label>
            </div>
            <input type="text" value={specification} onChange={specificationChange} />
          </div>
        </div>

        <div className="form-width-5">
          <div className="form-width">
            <div>
              <label>Description</label>
            </div>
            <input type="text" value={description} onChange={descriptionChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Image1</label>
            </div>
            {/* =========================================================================================
            -------------------------------------- CHANGES MADE -----------------------------------------
            ========================================================================================= */}
            <input
              type="file"
              // value = {imageOne}
              // name = 'image'
              onChange={imageOneChange}
              multiple
            />
          </div>
          <div className="form-width">
            {/* <div>
                            <label>Image2</label>
                        </div>
                        <input 
                            type = "file" 
                            // value = {imageTwo}
                            // name = "imageone"
                            onChange = {imageTwoChange}
                        /> */}
          </div>
          <div className="form-width">
            {/* <div>
                            <label>Image3</label>
                        </div>
                        <input 
                            type = "file" 
                            // name = "imageTwo"
                            // value = {ImageThree}
                            onChange = {imageThreeChange}
                        /> */}
          </div>
        </div>

        <div className="form-width-2">
          <div className="form-width">
            <div>
              <label>Delivery Option</label>
            </div>
            <input type="text" value={deliveryOption} onChange={deliveryOptionChange} />
          </div>
          <div className="form-width">
            <div>
              <label>Available Quantity</label>
            </div>
            <input type="text" value={availableQuantity} onChange={availableQuantityChange} />
          </div>
        </div>

        <button type="submit" className="product-button">
          Update
        </button>
      </form>
    </div>
  );
};

export default ProductEdit;
