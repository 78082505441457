import { useEffect, useState } from "react";
import axios from "axios";
import S3FileUpload from "react-s3";
import "./index.css";
import { nanoid } from "nanoid";

const Ads = () => {
  const [bannerAd, setBannerAd] = useState("");
  const [featureAd, setFeatureAd] = useState("");
  const [resourcesAd, setResourcesAd] = useState("");
  const [productsFile, setProductsFile] = useState("");
  const [homeFile, setHomeFile] = useState("");
  const [resourceFile, setResourceFile] = useState("");
  const [featureFile, setFeatureFile] = useState("");
  const [adApiData, setAdApiData] = useState([]);
  const [homeImg, setHomeImg] = useState("");
  const bannerId = "616d46d9972cbf49b3a4cee1";
  const featureId = "616d5dd5797037727ca808e3";
  const resourcesId = "616d5def797037727ca808e5";
  const homeId = "61891a770f042f38b087984b";
  const url = process.env.REACT_APP_API_ENDPOINT + "/api/ad";

  useEffect(() => {
    fetchAdAPI();
  }, []);

  const bannerChange = (e) => {
    encodeBannerImageFileAsURL(e.target.files);
    const fileList = e.target.files;
    const imageFile = fileList[0];
    let blob = imageFile.slice(0, imageFile.size, "image/png");
    setProductsFile(new File([blob], fileList[0].name+nanoid(), { type: "image/png" }));
  };
  const featureChange = (e) => {
    encodeFeatureImageFileAsURL(e.target.files);
    const fileList = e.target.files;
    const imageFile = fileList[0];
    let blob = imageFile.slice(0, imageFile.size, "image/png");
    setFeatureFile(new File([blob], fileList[0].name+nanoid(), { type: "image/png" }));
  };
  const resourcesChange = (e) => {
    encodeResourcesImageFileAsURL(e.target.files);
    const fileList = e.target.files;
    const imageFile = fileList[0];
    let blob = imageFile.slice(0, imageFile.size, "image/png");
    setResourceFile(new File([blob], fileList[0].name+nanoid(), { type: "image/png" }));
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const homeChange = (e) => {
    const fileList = e.target.files;
    const imageFile = fileList[0];
    let blob = imageFile.slice(0, imageFile.size, "image/png");
    setHomeFile(new File([blob], fileList[0].name+nanoid(), { type: "image/png" }));
  };
  const fetchAdAPI = async () => {
    try {
      const ad = await axios.get(url);
      setAdApiData(ad.data);
      console.log("API Fetched");
    } catch (err) {
      console.log(err);
      console.log("unable to fetch");
    }
  };

  const encodeBannerImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setBannerAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeFeatureImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setFeatureAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeResourcesImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setResourcesAd(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const encodeHomeImageFileAsURL = (element) => {
    var base64;
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      base64 = reader.result;
      setHomeImg(reader.result);
      console.log("Base64", base64);
    };
    reader.readAsDataURL(file);
  };
  const updateBannerImage = async (e) => {
    //   e.preventDefault();
    //   const adBanner = { adType: "productspage_saleAD", adImage: bannerAd };
    //   await axios
    //     .put(`${process.env.REACT_APP_API_ENDPOINT}/api/ad/${bannerId}`, adBanner)
    //     .then((response) => console.log(response));
    // };

    e.preventDefault();
    S3FileUpload.uploadFile(productsFile, config)
      .then((data) => {
        console.log(data);
        const adBanner = {
          adType: "productspage_saleAD",
          adImage: data.key.slice(
            data.key.lastIndexOf("/") + 1,
            data.key.length
          ),
        };
        axios
          .put(
            `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${bannerId}`,
            adBanner
          )
          .then((response) => console.log(response))
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateFeatureImage = async (e) => {
    // e.preventDefault();
    // const adFeature = { adType: "featureProductAd", adImage: featureAd };
    // await axios
    //   .put(
    //     `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${featureId}`,
    //     adFeature
    //   )
    //   .then((response) => console.log(response));

    e.preventDefault();
    S3FileUpload.uploadFile(featureFile, config)
      .then((data) => {
        console.log(data);
        const adFeature = {
          adType: "featureProductAd",
          adImage: data.key.slice(
            data.key.lastIndexOf("/") + 1,
            data.key.length
          ),
        };
        axios
          .put(
            `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${featureId}`,
            adFeature
          )
          .then((response) => console.log(response))
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateResourcesImage = async (e) => {
    // e.preventDefault();
    // const adResoures = { adType: "topResourcesAd", adImage: resourcesAd };
    // await axios
    //   .put(
    //     `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${resourcesId}`,
    //     adResoures
    //   )
    //   .then((response) => console.log(response));

    e.preventDefault();
    S3FileUpload.uploadFile(resourceFile, config)
      .then((data) => {
        console.log(data);
        const adResoures = {
          adType: "topResourcesAd",
          adImage: data.key.slice(
            data.key.lastIndexOf("/") + 1,
            data.key.length
          ),
        };
        axios
          .put(
            `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${resourcesId}`,
            adResoures
          )
          .then((response) => console.log(response))
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateHomeImage = async (e) => {
    e.preventDefault();
    // S3FileUpload.uploadFile(homeFile, config);
    // const HomeResoures = { adType: "HomeAD", adImage: homeFile };
    // await axios
    //   .put(
    //     `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${homeId}`,
    //     HomeResoures
    //   )
    //   .then((response) => console.log(response));
    S3FileUpload.uploadFile(homeFile, config)
      .then((data) => {
        console.log(data);
        const HomeResoures = {
          adType: "HomeAD",
          adImage: data.key.slice(
            data.key.lastIndexOf("/") + 1,
            data.key.length
          ),
        };
        axios
          .put(
            `${process.env.REACT_APP_API_ENDPOINT}/api/ad/${homeId}`,
            HomeResoures
          )
          .then((response) => console.log(response))
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="Ads">
      <div>
        <h5>Sale banner in Products page (size 730x375)</h5>
        <div className="ad-grid">
          {adApiData
            .filter((data) => data.adType === "productspage_saleAD")
            .map((ad) => (
              <div key={ad._id}>
                <img
                  src={process.env.REACT_APP_BUCKET_BASE_URL + ad.adImage}
                  alt="ad-missing"
                  className="banner-ad-image"
                />
              </div>
            ))}
          <div>
            <div>
              <input
                type="file"
                className="input-grey"
                onChange={bannerChange}
              />
            </div>
            <div>
              <button className="btn-red" onClick={updateBannerImage}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h5>Add in Featured Products in Website (size 350x630)</h5>

        <div className="ad-grid">
          {adApiData
            .filter((data) => data.adType === "featureProductAd")
            .map((ad) => (
              <div key={ad._id}>
                <img
                  src={process.env.REACT_APP_BUCKET_BASE_URL + ad.adImage}
                  alt="ad-missing"
                  className="feature-ad-image"
                />
              </div>
            ))}
          <div>
            <div>
              <input
                type="file"
                className="input-grey"
                onChange={featureChange}
              />
            </div>
            <div>
              <button className="btn-red" onClick={updateFeatureImage}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h5>Add in Top Resources in Website (size 250x700)</h5>

        <div className="ad-grid">
          {adApiData
            .filter((data) => data.adType === "topResourcesAd")
            .map((ad) => (
              <div key={ad._id}>
                <img
                  src={process.env.REACT_APP_BUCKET_BASE_URL + ad.adImage}
                  alt="ad-missing"
                  className="resources-ad-image"
                />
              </div>
            ))}
          <div>
            <div>
              <input
                type="file"
                className="input-grey"
                onChange={resourcesChange}
              />
            </div>
            <div>
              <button className="btn-red" onClick={updateResourcesImage}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h5>Home Screen main banner (size 420x1000)</h5>

        <div className="ad-grid">
          {adApiData
            .filter((data) => data.adType === "HomeAD")
            .map((ad) => (
              <div key={ad._id}>
                <img
                  src={process.env.REACT_APP_BUCKET_BASE_URL + ad.adImage}
                  alt="ad-missing"
                  className="home-ad-image"
                />
              </div>
            ))}
          <div>
            <div>
              <input type="file" className="input-grey" onChange={homeChange} />
            </div>
            <div>
              <button className="btn-red" onClick={updateHomeImage}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
