import { NavLink } from 'react-router-dom';

import logo from "../../Images/logo.png";

import './index.css';

const CoachNavbar = () => {
    const userEmail = localStorage.getItem('email');
    let userName = localStorage.getItem('userName');
    let id = localStorage.getItem('coachId');
    userName = userName === undefined || userName === 'undefined' ? '' : userName;
    return (
      <div className="Navbar">
        <div className="side-navbar-coach">
          <img className="navbar-logo" src={logo} alt="logo-missing" />
          <div className="userinfo-container">
            <span>{userName || ''}</span>
            <span>{userEmail || ''}</span>
          </div>
          <div className="navlinks-container">
            <div>
              <NavLink
                exact
                to="/list-blogs"
                activeClassName="active"
                className="nav-link"
              >
                Blogs
              </NavLink>
            </div>
            <div>
              <NavLink
                exact
                to="/coach-profile"
                activeClassName="active"
                className="nav-link"
              >
                Profiles
              </NavLink>
            </div>
            <div>
              <NavLink
                exact
                to='myprofile-edit'
                activeClassName="active"
                className="nav-link"
              >
                My Profile
              </NavLink>
            </div>
            <div>
              <NavLink
                exact
                to="/my-students"
                activeClassName="active"
                className="nav-link"
              >
                Students
              </NavLink>
            </div>
            
            <div>
              <NavLink
                exact
                to={`/package/${id}`}
                activeClassName="active"
                className="nav-link"
              >
                Packages
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CoachNavbar;