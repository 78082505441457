// import { useState, useEffect } from "react";
import Button from "@restart/ui/esm/Button";
import axios from "axios";
import moment from "moment";
import React from 'react';
import { Modal, Table } from "react-bootstrap";
import "./index.css";

class Payouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      orderDetails: [],
      totalOutstandings: 0,
      dialog: false
    };

    this.paynowData = [];
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/transaction")
    .then((orderApi) => {
      const orderData = orderApi.data;
      let individualSupplier = [];
      let supp = [];
      let totalOutstandings = 0;
      orderData.forEach((data) => {
        data.productDetails = data.productDetails.map(pData => {
          pData.transactionId = data._id;
          return pData;
        });
        individualSupplier.push(data.productDetails);
      });
      individualSupplier.map((data) => {
        data.forEach((d) => {
          if (d.pTotal && d.payoutStatus === 'not_paid') {
            totalOutstandings += (parseFloat(d.pTotal) - ((10/100) * parseFloat(d.pTotal)));
          }
          supp.push(d);
        });
      });
      totalOutstandings = Math.round(totalOutstandings * 100) / 100;
      this.setState({orderData: orderData, orderDetails: supp, totalOutstandings});
    }).catch(err => console.log(err));
  }

  handleClose = (boolVal = false, data) => {
    this.setState({dialog: boolVal}, () => {
      this.paynowData = data;
    })
  }

  paynow = (pId, transactionId) => {
    const payload = {
      pId,
      transactionId,
      status: 'paid'
    };

    axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/transaction/update", payload)
    .then((orderApi) => {
      this.setState({dialog: false});
      this.getOrders();
    })
    .catch(err => console.log(err));
  }

  render() {
    return (
      <div className="payout-container">
        <div className="row" style={{marginBottom: 15}}>
          <strong>Payouts</strong>
        </div>
        <div className="row" style={{marginBottom: 30}}>
          <div className="col-md-3"></div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            Outstanding to pay <span className="ot-amount">${this.state.totalOutstandings}</span>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <Table className="product-table" hover>
            <thead className="thead-cr" style={{height: '40px', fontSize: '14px'}}>
              <tr className="tr-cr-head">
                <th>No</th>
                <th style={{width: '15vw'}}>Supplier Name</th>
                <th style={{width: '10vw'}}>Order No</th>
                <th style={{width: '13.4vw'}}>Order Value</th>
                <th style={{width: '13.4vw'}}>Payout Amount</th>
                <th style={{width: '10vw'}}>Payout Status</th>
                <th style={{width: '13vw'}}>Purchased On</th>
                <th style={{width: '8vw'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.orderDetails.map((data, index) => {
                  return (
                    <tr key={data.pId + index} className="tr-cr">
                      <td>{index + 1}</td>
                      <td>{data.supplierName || '--'}</td>
                      <td>{data.orderId}</td>
                      <td>${data.pTotal || '--'}</td>
                      <td>${data.pTotal - ((10/100) * data.pTotal)}</td>
                      <td>
                        {
                          data.payoutStatus === 'paid'
                          ? <font color="green">Paid</font>
                          : <font color="#a49000">Not Paid</font>
                        }
                      </td>
                      <td style={{whiteSpace: 'nowrap'}}>{data.purchasedOn ? moment(new Date(data.purchasedOn)).format('Do-MMM-Y h:mmA') : ''}</td>
                      {
                        data.payoutStatus === 'paid'
                        ? <td className="paid">Paid</td>
                        : <td onClick={() => this.handleClose(true, [data.pId, data.transactionId])} className="paynow">Pay Now</td>
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
        <Modal show={this.state.dialog} onHide={() => this.handleClose(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title style={{color: 'red'}}>Warning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure payment is completed?
          </Modal.Body>
          <Modal.Footer>
            <Button className="dec-button cancel-dec" variant="secondary" onClick={() => this.handleClose(false)}>
              Cancel
            </Button>
            <Button className="dec-button yes-dec" variant="primary" onClick={() => this.paynow(...this.paynowData)}>Yes</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Payouts;
