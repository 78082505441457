import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import './index.css';

export default class ListSuppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliersData: []
    };
  }

  componentDidMount() {
    this.getSellerData();
  }

  getSellerData = async () => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/users').then((apiData) => {
      const suppliersList = apiData.data.filter(
        (supplier) => supplier.userType === "seller"
      );
      this.setState({suppliersData: suppliersList});
    }).catch(err => {

    });
  }

  render() {
    return (
      <div className="ls-container">
        <div className="row" style={{marginBottom: 15}}>
          <div className="col-md-6 product-heading-nav">
            <strong>Suppliers</strong>
          </div>
          <div className="col-md-6 product-list-nav">
            <button
              onClick={() => this.props.history.push('/create-suppliers')}
              className="ls-button">Add New Supplier</button>
          </div>
        </div>
        <div className="row">
          <Table className="product-table" hover>
              <thead className="thead-cr" style={{height: '40px', fontSize: '14px'}}>
              <tr className="tr-cr-head">
                  <th>No</th>
                  <th style={{width: '20vw'}}>Seller Name</th>
                  <th style={{width: '15vw'}}>Mobile</th>
                  <th style={{width: '25vw'}}>Email</th>
                  <th style={{width: '20vw'}}>Added On</th>
                  <th style={{width: '8vw'}}>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.suppliersData.map((data, index) => {
                  return (
                    <tr key={data.pId + index} className="tr-cr">
                      <td>{index + 1}</td>
                      <td>{data.userName}</td>
                      <td>{data.mobileNumber}</td>
                      <td>{data.email}</td>
                      <td style={{whiteSpace: 'nowrap'}}>
                        {data.createdAt ? moment(new Date(data.createdAt)).format('Do-MMM-Y h:mmA') : ''}
                      </td>
                      <td style={{
                        fontSize: 20
                      }}>
                        <i
                          className="fa fa-eye red"
                          aria-hidden="true"
                          onClick={this.statusClick}
                        ></i>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
          </Table>
        </div>
      </div>
    );
  }
}