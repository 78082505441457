import "./App.css";
import { useState } from "react";

import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import Login from "./Components/Login";
import Navbar from "./Components/Navbar";
import SellerAd from "./Components/SellerAd";
import Ads from "./Components/Ads/Index";
import ChangePassword from "./Components/ChangePassword";
import Clips from "./Components/Clips";
import DashBoard from "./Components/Dashboard";
import Payouts from "./Components/Payouts";
import ReportedProfile from "./Components/ReportedProfile";
import Header from "./Components/Header";
import Products from "./Components/Products";
import ProductList from "./Components/Products/ProductList";
import ProductEdit from "./Components/Products/ProductEdit";
import CreateSuppliers from "./Components/Suppliers/CreateSuppliers";
import ListSuppliers from "./Components/Suppliers/ListSuppliers";
import Quotes from "./Components/Quotes";
import ListBlogs from "./Components/MyBlogs/ListBlogs";
import MyStudents from "./Components/My Students";
import Packages from "./Components/Packages";
import QuoteEdit from "./Components/Quotes/EditQuotes";
import CreateBlog from "./Components/MyBlogs/CreateBlog";
import BlogEdit from "./Components/MyBlogs/EditBlogs";
import CreateProfile from "./Components/Profile/CreateProfile";
import ListProfile from "./Components/Profile/ListProfile";
import EditProfile from "./Components/Profile/EditProfile";
import CoachNavbar from "./Components/CoachNavbar";
import SupplierNavbar from "./Components/SupplierNavbar";
import PageNotFound from "./Components/PageNotFound";
import { useEffect } from "react";
import SupplierDashBoard from "./Components/SupplierDashboard";
import ForgetPasswordEmail from "./Components/ForgetPasswordEmail/ForgetPasswordEmail";
import SupplierPackages from "./Components/SupplierPackages";
import MyOrders from "./Components/CoachOrders/MyOrders";
import OrderDetails from "./Components/CoachOrders/OrderDetails";
import store from "./Components/Redux/reduxOrderDetails/store";
import EnterOtp from "./Components/EnterOtp";
import ResetPassword from "./Components/ResetPassword";
import FeaturedItems from "./Components/FeaturedItems";
import FeaturedQuote from "./Components/FeaturedQuote";
import CoachRequests from "./Components/CoachRequests";
import axios from "axios";
import PageLoading from "./Components/PageLoading";
import CoachMyProfile from "./Components/CoachMyProfile/CoachMyProfile";

function App() {
  const [userType, setUserType] = useState(null);
  const [landing, setLanding] = useState("load");

  const checkLogin = () => {
    const token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/api/users/login", {
        headers: {
          "x-auth": token,
        },
      })
      .then((response) => {
        setUserType(response.data.userType);
        setLanding("dashboard");
      })
      .catch((err) => {
        setUserType(null);
        setLanding("login");
        if (window.location.href !== window.location.origin + "/") {
          window.location.href = window.location.origin;
        }
      });
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <>
      {landing === "load" ? (
        <PageLoading />
      ) : (
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/enter-otp" component={EnterOtp} />
              <Route
                exact
                path="/enter-email"
                component={ForgetPasswordEmail}
              />
              <Route exact path="/reset-password" component={ResetPassword} />
            </Switch>
          </Router>
          {userType === "admin" && (
            <Router>
              <Navbar />
              <div className="main-section">
                <Header />
                <Switch>
                  <Route exact path="/dashboard" component={DashBoard} />
                  <Route
                    exact
                    path="/create-suppliers"
                    component={CreateSuppliers}
                  />
                  <Route
                    exact
                    path="/list-suppliers"
                    component={ListSuppliers}
                  />
                  <Route exact path="/payouts" component={Payouts} />
                  <Route exact path="/ads" component={Ads} />
                  <Route
                    exact
                    path="/reported-profile"
                    component={ReportedProfile}
                  />
                  <Route
                    exact
                    path="/change-password"
                    component={ChangePassword}
                  />
                  <Route exact path="/clips" component={Clips} />
                  <Route exact path="/quotes" component={Quotes} />
                  <Route exact path="/quote-edit/:id" component={QuoteEdit} />
                  <Route
                    exact
                    path="/featured-items"
                    component={FeaturedItems}
                  />
                  <Route
                    exact
                    path="/featured-quote"
                    component={FeaturedQuote}
                  />
                  <Route
                    exact
                    path="/coach-requests"
                    component={CoachRequests}
                  />
                </Switch>
              </div>
            </Router>
          )}
          {userType === "coach" && (
            <Router>
              <CoachNavbar />
              <div className="main-section">
                <Header />
                <Switch>
                  <Route exact path="/create-blog" component={CreateBlog} />
                  <Route exact path="/blog-edit/:id" component={BlogEdit} />
                  <Route exact path="/list-blogs" component={ListBlogs} />
                  <Route exact path="/coach-profile" component={ListProfile} />
                  <Route
                    exact
                    path="/create-profile/:profileNum"
                    component={CreateProfile}
                  />
                  <Route
                    exact
                    path="/profile-edit/:id/:profileNum"
                    component={EditProfile}
                  />
                  <Route
                    exact
                    path="/myprofile-edit"
                    component={CoachMyProfile}
                  />
                  <Route exact path="/my-students" component={MyStudents} />
                  <Route exact path="/package/:id" component={Packages} />
                </Switch>
              </div>
            </Router>
          )}
          {userType === "seller" && (
            <Router>
              <SupplierNavbar />
              <div className="main-section">
                <Header />
                <Switch>
                  <Route
                    exact
                    path="/dashboard"
                    component={SupplierDashBoard}
                  />
                  <Route exact path="/products" component={Products} />
                  <Route exact path="/ads" component={SellerAd} />
                  <Route exact path="/products-list" component={ProductList} />
                  <Route
                    exact
                    path="/product-edit/:id"
                    component={ProductEdit}
                  />
                  <Route exact path="/packages" component={SupplierPackages} />
                  <Route exact path="/myorders" component={MyOrders} />
                  <Route exact path="/order-details" component={OrderDetails} />
                </Switch>
              </div>
            </Router>
          )}
        </div>
      )}
    </>
  );
}
export default App;

{
  /* <Router>
        <SupplierNavbar />
        <Header />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/products-list" component={ProductList} />
          <Route exact path="/product-edit/:id" component={ProductEdit} />
          <Route exact path="/ads" component={Ads} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/clips" component={Clips} />
          <Route exact path="/dashboard" component={DashBoard} />
          <Route exact path="/payouts" component={Payouts} />
          <Route exact path="/reported-profile" component={ReportedProfile} />
          <Route exact path="/create-suppliers" component={CreateSuppliers} />
          <Route exact path="/list-suppliers" component={ListSuppliers} />
          <Route exact path="/create-profile" component={CreateProfile} />
          <Route exact path="/quotes" component={Quotes} />
          <Route exact path="/coach-profile" component={ListProfile} />
          <Route exact path="/list-blogs" component={ListBlogs} />
          <Route exact path="/my-students" component={MyStudents} />
          <Route exact path="/packages" component={Packages} />
          <Route exact path="/quote-edit/:id" component={QuoteEdit} />
          <Route exact path="/create-blog" component={CreateBlog} />
          <Route exact path="/blog-edit/:id" component={BlogEdit} />
          <Route exact path="/profile-edit/:id" component={EditProfile} />
        </Switch>
      </Router> */
}
