import axios from "axios";
import { useState } from "react";
import AddClips from "./AddClips";
import ClipsList from "./ClipsList";
import "./index.css";

const Clips = () => {
  const [clipData, setClipData] = useState([]);

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/clip";

  const getClipData = async () => {
    try {
      const clips = await axios.get(url);
      setClipData(clips.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="Clips">
      <AddClips getClipData={getClipData} />
      <ClipsList getClipData={getClipData} clipData={clipData} />
    </div>
  );
};

export default Clips;
