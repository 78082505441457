import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./index.css";
import logo from "../../Images/logo.png";
import WhiteLogo from "../../Images/cornerco-logo.png";


const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const id = useSelector((state) => state.login.forgetUserId.id);
  // const id =  useSelector((state) => console.log(state))
  const history = useHistory();

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };
  const rePasswordChange = (e) => {
    setRePassword(e.target.value);
  };
  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    console.log(password, 'password');
    console.log(rePassword, 'repassword');
    let confirmPassword = '';
    if (password === rePassword) {
      confirmPassword = password;
      const data = { password: confirmPassword };
      console.log(id);
      console.log(confirmPassword, 'confirmPAsword');
      console.log(data, 'data');
      try {
        await axios
          .put(`http://localhost:3001/api/users/resetpass/${id}`, data)
          .then(setPassword(""), setRePassword(""))
          setSuccess(true)
          setInterval(()=>{
            history.push("/");
          },3000)
          
      } catch (err) {
        console.log(err);
      }
      console.log('change success')
    } else {
      setMismatch(true)
      setInterval(()=>{
        setMismatch(false)
      },5000)
      console.log("password mismatch");
    }
   
    
   
  };

  return (
    <>
    <div className="Login">
      <div className="logo">
        <img src={logo} className="login-logo" alt="logo" />
        <h2>Cornerco | <span style={{color: 'white'}}>Admin Panel</span></h2>
      </div>
      <div className="reset-form">
      <form onSubmit={resetPassword}>
        <h3>Reset Password</h3>

        <div className='_reset_'>
        <div>
          <label className='_passLabel_'>Password:</label>
          <input
          className='_passInput_'
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={passwordChange}
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>
        </div>
        <div>
          <label className='_passLabel_'>Retype Password:</label>
          <input
          className='_passInput_'
            type={showPassword ? "text" : "password"}
            value={rePassword}
            onChange={rePasswordChange}
          />
          <i
            className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
            onClick={showPasswordClick}
            aria-hidden="true"
          ></i>
        </div>
        {mismatch ? <p className="password_mismatch_warning">Password Mismatch.</p> :""}
        {success ? <p className="password_reset_successfull">Password Reset Complete.</p> :""}
        </div>
        <button type="submit">Reset</button>
      </form>
    </div>
    </div>
    
    </>
  );
};

export default ResetPassword;
